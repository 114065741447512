import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultButton = _resolveComponent("DefaultButton")!

  return (_openBlock(), _createBlock(_component_DefaultButton, {
    onClick: _ctx.resetAndGoToHome,
    class: _normalizeClass(["btn btn--extra-large", {
      'is-active': _ctx.isHomeRoute,
    }]),
    name: 'home-icon'
  }, null, 8, ["onClick", "class"]))
}