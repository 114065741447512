
import { Options, mixins } from "vue-class-component";
import { assetsModule } from "@/store";
import {
  AssetsInterface,
  FoldersWithAssetsInterface,
  FiltersInterface,
} from "@/types";
import { getAvailableTags } from "@/utils/helpers";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import { useMq } from "vue3-mq";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import AlbumList from "./AlbumList/index.vue";
import { smoothAutoScroll } from "@/utils";

const ALBUMS_ROUTE = "3d-viewer/albums";
const HOME_ROUTE = "3d-viewer/home";

@Options({
  name: "AlbumsWithAssets",
  components: { AlbumList, SvgIcon },
  watch: {
    async "mq.current"(val) {
      this.filteredAlbums = await assetsModule.FilterAlbumsByCustomFilter(
        this.getFilters
      );
    },
    filteredAlbums(albums: FoldersWithAssetsInterface[]) {
      assetsModule.setFilteredAlbums([...albums]);
    },
    async getFilters(filters) {
      this.filteredAlbums = await assetsModule.FilterAlbumsByCustomFilter(
        filters
      );

      this.setAvailableTags(filters.tags);

      if (this.isDesktop) {
        if (this.isHomeRoute && this.isFiltersActive(filters)) {
          this.redirectToAlbum("");
        }

        if (this.isAlbumsRoute && this.isFiltersActive(filters)) {
          this.redirectToAlbum(this.getValidAlbumPath);
        }

        if (this.isAssetRoute && this.isFiltersActive(filters)) {
          this.filteredAlbums.length
            ? this.handleFirstAssetRedirect(this.filteredAlbums)
            : await this.redirectToRoute("/");
        }
      }
    },
    async dataLoadingInProgress(val) {
      if (!val) {
        this.filteredAlbums = await assetsModule.FilterAlbumsByCustomFilter(
          this.getFilters
        );
      }
    },
    $route: {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => this.scrollToActiveElement());
      },
    },
  },
})
export default class AlbumsWithAssets extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  protected mq = useMq();
  protected tags!: string[];
  protected filteredAlbums: FoldersWithAssetsInterface[] = [];

  protected get dataLoadingInProgress(): boolean {
    return assetsModule.getAllDataLoader;
  }

  protected get getAssets(): AssetsInterface[] {
    return assetsModule.getAssets;
  }

  protected get isAssetRoute(): boolean {
    return !!this.$route.params.assetSlug;
  }

  protected get isDesktop(): boolean {
    return ["lg", "xl", "xxl"].includes(this.mq.current);
  }

  protected get isAlbumsRoute(): boolean {
    return this.$route.name === ALBUMS_ROUTE;
  }

  protected get isHomeRoute(): boolean {
    return this.$route.name === HOME_ROUTE;
  }

  protected get getFilters(): FiltersInterface {
    return assetsModule.getFilters;
  }

  protected get getValidAlbumPath(): string[] | string | null {
    const currentRouteAsArray: string[] | string = [
      ...this.$route.params.albumPath,
    ];

    while (currentRouteAsArray && currentRouteAsArray.length > 0) {
      const foundAlbum = this.findNestedAlbumBySlug(
        currentRouteAsArray[currentRouteAsArray.length - 1],
        this.filteredAlbums
      );

      if (foundAlbum) {
        return currentRouteAsArray;
      }

      currentRouteAsArray.pop();
    }

    return "";
  }

  protected setAvailableTags(tags: string[]): void {
    this.emitter.emit(
      "available-tags",
      getAvailableTags({
        assets: this.getAssets,
        tags: tags,
      })
    );
  }

  protected handleFirstAssetRedirect(
    albums: FoldersWithAssetsInterface[]
  ): void {
    const assets = this.extractNestedAssets(albums);
    const assetSlug = this.$route.params.assetSlug;
    const asset = assets.find(
      (asset: AssetsInterface) => asset.slug === assetSlug
    );

    if (!asset) {
      const [firstAsset] = assets;

      if (firstAsset) {
        const assetPath = this.getFullAssetRouteAsString(firstAsset);
        this.redirectToRoute(assetPath);
      }
    }
  }

  protected isFiltersActive(filters: FiltersInterface): boolean {
    return Object.entries(filters).some(([key, value]) => value.length > 0);
  }

  protected scrollToActiveElement(): void {
    const activeElement = this.$el.querySelector(".item--active");

    if (!activeElement || !this.$refs.scrollbar) {
      return;
    }

    const scrollbar = this.$refs.scrollbar as any;
    const container = scrollbar.$el;
    const containerHeight = container.clientHeight;
    const activeElementOffsetTop = activeElement.offsetTop;
    const activeElementHeight = activeElement.offsetHeight + 30;
    const scrollTop = scrollbar.ps.lastScrollTop;

    const isAboveVisibleArea = activeElementOffsetTop < scrollTop;
    const isBelowVisibleArea =
      activeElementOffsetTop + activeElementHeight >
      scrollTop + containerHeight;

    if (isAboveVisibleArea || isBelowVisibleArea) {
      const targetScrollPosition =
        activeElementOffsetTop - (containerHeight - activeElementHeight) / 2;

      smoothAutoScroll(scrollbar, targetScrollPosition);
    }
  }

  async mounted(): Promise<void> {
    if (!this.filteredAlbums.length) {
      this.filteredAlbums = await assetsModule.FilterAlbumsByCustomFilter(
        this.getFilters
      );
    }
  }
}
