
import { Vue, Options } from "vue-class-component";
import { assetsModule, usersModule } from "@/store";
import { checkAccess } from "@/permission";
import { AccessTypes, AssetsInterface, PinnedAssetInterface } from "@/types";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { PropType } from "vue";

@Options({
  name: "ToggleAssetPinButton",
  components: { SvgIcon },
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
})
export default class ToggleAssetPinButton extends Vue {
  protected asset!: AssetsInterface;

  protected get pinnedAssets(): AssetsInterface[] {
    return assetsModule.getPinnedAssets;
  }

  protected get isPinnedAssetsLoading(): boolean {
    return assetsModule.getPinnedAssetsLoader;
  }

  protected get hasAccessToAsset(): boolean {
    return checkAccess(
      usersModule.userData.access as number,
      AccessTypes.Registered
    );
  }

  protected get isPinned(): boolean {
    if (this.asset && this.hasAccessToAsset) {
      const pinnedAssetIndex = this.pinnedAssets.findIndex(
        (pinnedAsset: AssetsInterface) => pinnedAsset.id == this.asset!.id
      );
      return pinnedAssetIndex > -1;
    } else {
      return false;
    }
  }

  protected handlePin(asset: AssetsInterface | undefined, isPinned: boolean): void {
    if (usersModule.IsAuthenticated) {
      if (isPinned) {
        assetsModule.UnpinAsset(asset!.id);
      } else {
        assetsModule.PinAsset(asset!.id);
      }
    }
  }

  protected buttonTitle(hasAccessToAsset: boolean, isPinned: boolean): string {
    if (!hasAccessToAsset) {
      return "Only registered users can pin assets";
    } else if (isPinned) {
      return "Unpin this asset from your collection";
    } else {
      return "Pin this asset to your collection";
    }
  }
}
