
import { Options, Vue } from "vue-class-component";
import { assetsModule } from "@/store";
import AlbumList from "@/components/ui/BaseAlbumsWithAssets/index.vue";
import AlbumListFilter from "./AlbumListFilter/index.vue";
import debounce from "debounce";
import { useMq } from "vue3-mq";
import { FiltersInterface } from "@/types";

@Options({
  name: "AlbumFilterAndDisplayComponent",
  components: { AlbumList, AlbumListFilter },
  props: {
    showPanel: {
      type: Boolean,
      default: false,
    },
    showAssetSearch: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getFilters() {
      this.getAlbumListFilterHeight();
    },
    $route: {
      immediate: true,
      handler() {
        this.getAlbumListFilterHeight();
      },
    },
  },
})
export default class AlbumFilterAndDisplayComponent extends Vue {
  $refs!: {
    albumListFilter: HTMLFormElement;
    albumList: HTMLFormElement;
  };

  protected mq = useMq();

  protected get getFilters(): FiltersInterface {
    return assetsModule.getFilters;
  }

  private getAlbumListFilterHeight(): void {
    if (this.$refs.albumListFilter) {
      const albumListFilter = this.$refs.albumListFilter;

      if (albumListFilter) {
        const albumListFilterElement = albumListFilter.$el as HTMLElement;
        const albumListFilterHeight = albumListFilterElement.offsetHeight;

        if (this.$refs.albumList) {
          const basePadding = 40 + 10 + 20;
          const paddings =
            basePadding +
            (["xs", "sm", "md"].includes(this.mq.current) ? 80 : 0);
          const headerHeight = 60;
          const footerHeight = 40; //
          const exceptHeight = headerHeight + paddings + footerHeight;
          let listHeight =
            window.innerHeight - exceptHeight - albumListFilterHeight;

          this.$refs.albumList.$el.style.setProperty(
            "--listHeight",
            `${listHeight}px`
          );
        }
      }
    }
  }

  private resizeHandler(): void {
    this.getAlbumListFilterHeight();
  }

  beforeMount(): void {
    window.addEventListener(
      "resize",
      debounce(() => {
        this.resizeHandler();
      }, 200)
    );
  }

  mounted(): void {
    this.getAlbumListFilterHeight();
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
