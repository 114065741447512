import { assetsUrlsInterface } from "@/types";
import {
  AssetsApiClientInterface,
  AssetsApi,
} from "@/apiClient/models/api/assets/assetsApiClient.models";

const urls: assetsUrlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/en/assets`,
  fetchAlbums: `${process.env.VUE_APP_API_URL}/en/albums`,
  fetchTags: `${process.env.VUE_APP_API_URL}/en/tags`,
  fetchPinnedAssets: `${process.env.VUE_APP_API_URL}/en/users/:userId/assets`,
  markAssetAsPinned: `${process.env.VUE_APP_API_URL}/en/users/:userId/assets/:assetId`,
  unmarkAssetAsPinned: `${process.env.VUE_APP_API_URL}/en/users/:userId/assets/:assetId`,
  sortPinnedAssets: `${process.env.VUE_APP_API_URL}/en/users/:userId/assets/sort`,
};

const assetsApiClient: AssetsApiClientInterface = new AssetsApi(urls);

export default assetsApiClient;
