
import { Vue, Options } from "vue-class-component";
import AssetTypeFilter from "./AssetTypeFilter/index.vue";
import HomeButton from "./HomeButton/index.vue";
import AlbumsButton from "./AlbumsButton/index.vue";
import PinnedAssetsButton from "./PinnedAssetsButton/index.vue";

@Options({
  name: "AssetManagementPanel",
  components: { HomeButton, PinnedAssetsButton, AlbumsButton, AssetTypeFilter },
})
export default class AssetManagementPanel extends Vue {
  protected updateAssetTypes(assetTypes: number[]): void {
    this.$emit("update-asset-types", assetTypes);
  }
}
