import { subMenuUrlsInterface } from "@/types";
import {
  MenuApiClientInterface,
  MenuApi,
} from "@/apiClient/models/api/menu/menuApiClient.models";

const urls: subMenuUrlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/en/menu`,
  fetchSubMenu: "/static/subMenuMockupData.json",
};

const menuApiClient: MenuApiClientInterface = new MenuApi(urls);

export default menuApiClient;
