<template>
  <p></p>
</template>

<script>
export default {
  name: "Default"
}
</script>

<style scoped>

</style>
