import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-80951a76")
const _hoisted_1 = {
  ref: "pageLayout",
  class: "page-layout"
}
const _hoisted_2 = { class: "main-inner" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_AssetsAndAlbumsDrawer = _resolveComponent("AssetsAndAlbumsDrawer")!
  const _component_TagsAndPinnedAssetsButtonDrawer = _resolveComponent("TagsAndPinnedAssetsButtonDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (['xs', 'sm', 'md'].includes(_ctx.mq.current))
          ? (_openBlock(), _createBlock(_component_AssetsAndAlbumsDrawer, { key: 0 }))
          : _createCommentVNode("", true),
        (['xs', 'sm', 'md'].includes(_ctx.mq.current))
          ? (_openBlock(), _createBlock(_component_TagsAndPinnedAssetsButtonDrawer, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 512))
}