
import { Vue, Options } from "vue-class-component";
import { assetsModule } from "@/store";
import AssetSearchByKeyFilter from "./AssetSearchByKeyFilter/index.vue";
import AssetManagementPanel from "./AssetManagementPanel/index.vue";
import TagsFilter from "./TagsFilter/index.vue";
import { FiltersInterface } from "@/types";

@Options({
  name: "AlbumListFilter",
  components: { AssetSearchByKeyFilter, AssetManagementPanel, TagsFilter },
  props: {
    showPanel: {
      type: Boolean,
      default: false,
    },
    showAssetSearch: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AlbumListFilter extends Vue {
  private filterObject: FiltersInterface = assetsModule.getFilters;

  protected updateCategories(categories: string[]): void {
    this.emitFilterUpdate({ categories });
  }

  protected updateTags(tags: string[]): void {
    this.emitFilterUpdate({ tags });
  }

  protected updateSearchQuery(newQuery: string): void {
    this.emitFilterUpdate({ searchQuery: newQuery });
  }

  protected updateAssetTypes(assetTypes: number[]): void {
    this.emitFilterUpdate({ assetTypes });
  }

  private emitFilterUpdate(filterUpdate: Partial<FiltersInterface>): void {
    this.filterObject = {
      ...assetsModule.getFilters,
      ...filterUpdate,
    };

    assetsModule.Filters(this.filterObject);
  }
}
