
import { Vue, Options } from "vue-class-component";

@Options({
  name: "PremiumHeader",
  props: {
    daysRemaining: {
      type: Object,
    },
    isYearlyPlan: {
      type: Boolean,
    },
  },
})
export default class PremiumHeader extends Vue {
  private readonly daysRemaining!: {
    days: number;
    hours: number;
    minutes: number;
  };
  private readonly isYearlyPlan!: boolean;

  protected get header(): string {
    const { days, hours, minutes } = this.daysRemaining;

    if (this.isYearlyPlan) {
      if (days === 0 && (hours > 0 || minutes > 0)) {
        return "Your Premium ends today";
      }

      if (days > 0) {
        const dayLabel = days === 1 ? "day" : "days";
        return `Your Premium ends in ${days} ${dayLabel}`;
      }
    }

    return "Your Premium has ended";
  }
}
