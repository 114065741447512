
import { Options, mixins } from "vue-class-component";
import {
  RoutingUtilityMixin,
  NoScrollMixin,
  HasAccessToAssetMixin,
} from "@/mixins";
import { PropType } from "vue";
import { AssetsInterface } from "@/types";
import { jsonToQueryString } from "@/utils";

@Options({
  name: "Model3DComponent",
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
  watch: {
    asset(val) {
      this.showAsset(val);
    },
  },
})
export default class Model3DComponent extends mixins(
  RoutingUtilityMixin,
  NoScrollMixin,
  HasAccessToAssetMixin
) {
  $refs!: {
    modelsIframe: HTMLFormElement;
  };
  private asset!: AssetsInterface;
  private precachedModels: string[] = [];

  protected onScanOnload(): void {
    const modelsIframe = this.$refs.modelsIframe;

    if (!modelsIframe) return;

    this.showAsset(this.asset);

    modelsIframe.addEventListener("mouseover", () =>
      this.preventScrollInElement(modelsIframe)
    );
  }

  protected get model3DPath(): string {
    const params: Record<string, any> = {
      wm: 0,
      autoplay: 1,
      lang: "en",
      hideButtons: 1,
      app: "refTool",
    };

    return `${
      process.env.VUE_APP_3D_VIEWER_RESOURCE_DOMAIN
    }/web_viewer.html?${jsonToQueryString(params)}`;
  }

  private isModelPrecached(modelName: string): boolean {
    return this.precachedModels.includes(modelName);
  }

  private addToPrecachedModels(modelName: string): void {
    this.precachedModels.push(modelName);
  }

  private showAsset(asset: AssetsInterface): void {
    if (!this.$refs.modelsIframe || !asset) return;

    const isPrecached = this.isModelPrecached(asset.src);

    if (!isPrecached) {
      this.addToPrecachedModels(asset.src);
      this.sendMessageToIframe("precacheAsset", [asset.src]);
    }

    this.sendMessageToIframe("showAsset", [asset.src]);

    if (asset.modelsPosition) {
      const delay = isPrecached ? 0 : 1000;
      setTimeout(() => {
        this.sendMessageToIframe("setCameraState", [asset.modelsPosition]);
      }, delay);
    }
  }

  private sendMessageToIframe(action: string, args: any[] = []): void {
    const modelsIframe = this.$refs.modelsIframe;

    if (modelsIframe && modelsIframe.contentWindow) {
      modelsIframe.contentWindow.postMessage(
        { action, args },
        process.env.VUE_APP_3D_VIEWER_RESOURCE_DOMAIN
      );
    }
  }

  beforeUnmount(): void {
    this.precachedModels = [];
  }
}
