import {MenuInterface, subMenuUrlsInterface, SubMenuDataInterface, responseInterface} from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/apiClient/models/http/httpClient.models";

export interface MenuApiClientInterface {
  fetch: (lang?: string) => Promise<responseInterface>;
  fetchSubMenu: () => Promise<SubMenuDataInterface>;
}

export class MenuApi implements MenuApiClientInterface {
  private readonly urls!: subMenuUrlsInterface;

  constructor(urls: subMenuUrlsInterface) {
    this.urls = urls;
  }

  fetch(lang?: string): Promise<responseInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch + (lang ? `?locale=${lang}` : ""),
      requiresToken: false,
    };

    return httpClient.get<responseInterface>(getParameters);
  }

  fetchSubMenu(): Promise<SubMenuDataInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchSubMenu,
      requiresToken: true,
    };

    return httpClient.get<SubMenuDataInterface>(getParameters);
  }
}
