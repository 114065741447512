
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import AccountDropdown from "./BaseButtonAccountDropdown/index.vue";
import { usersModule } from "@/store";

@Options({
  name: "AccountButton",
  components: {
    SvgIcon,
    AccountDropdown,
  },
})
export default class AccountButton extends Vue {
  protected get isUserAuth(): boolean {
    return usersModule.IsAuthenticated;
  }
}
