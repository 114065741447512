
import { Options, Vue } from "vue-class-component";
import YourPremiumStatusModal from "./YourPremiumStatusModal/index.vue";

@Options({
  name: "HomeComponentSlider",
  components: {
    YourPremiumStatusModal,
  },
})
export default class HomeComponentSlider extends Vue {
  protected isModalOpen = false;

  protected handleModalState(isVisible: boolean): void {
    this.isModalOpen = isVisible;
  }
}
