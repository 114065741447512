import { Vue } from "vue-class-component";
import {useMq} from "vue3-mq";

export default class HeaderMixin extends Vue {
  private prevScroll: number =
    window.scrollY || document.documentElement.scrollTop;
  private curScroll: number | null = null;
  private direction = 0;
  private prevDirection = 0;
  private header: any = null;
  protected mq = useMq();

  private checkScroll(e: Event): void {
    this.curScroll = window.scrollY || document.documentElement.scrollTop;

    this.direction = 1; //up
    if (this.curScroll > this.prevScroll) {
      this.direction = 2; //down
    }

    if (['lg', 'xl', 'xxl'].includes(this.mq.current) && this.direction !== this.prevDirection) {
      this.toggleHeader(this.direction, this.curScroll);
    }

    //--style class: for fixed header
    this.header.classList.remove("is-fixed");
    if (this.curScroll > this.header.clientHeight) {
      this.header.classList.add("is-fixed");
    }

    this.prevScroll = this.curScroll;
  }

  private toggleHeader(direction: number, curScroll: number): void {
    if (direction === 2 && curScroll > this.header.clientHeight) {
      this.onHideHeader(direction);
    } else if (direction === 1) {
      this.onShowHeader(direction);
    }
  }

  private onHideHeader(direction: number): void {
    this.header.style.setProperty(
      "--headerTop",
      `-${this.header.clientHeight + 10}px`
    );
    this.header.classList.add("is-hidden");
    this.prevDirection = direction;
  }

  private onShowHeader(direction: number): void {
    this.header.style = null;
    this.header.classList.remove("is-hidden");
    this.prevDirection = direction;
  }

  private onMouseMoveAction(e: any): void {
    const direction = 1; //up

    if (e.y < this.header.clientHeight / 2) {
      this.onShowHeader(direction);
    }
  }

  created() {
    window.addEventListener("scroll", this.checkScroll);
    window.addEventListener("mousemove", this.onMouseMoveAction);
  }

  destroyed() {
    window.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("mousemove", this.onMouseMoveAction);
  }
}
