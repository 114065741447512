
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin } from "@/mixins";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import DefaultButton from "@/components/ui/Buttons/BaseButtonDefault.vue";

@Options({
  name: "TagsButton",
  components: {
    SvgIcon,
    DefaultButton,
  },
})
export default class TagsButton extends mixins(RoutingUtilityMixin) {
  private isOpen = false;

  protected async toggleTags(): Promise<void> {
    if (this.isOpen) {
      this.$emit("reset-for-tags");
    } else {
      await this.redirectToRoute("/albums");
    }

    this.emitter.emit("drawer-tags-open", (this.isOpen = !this.isOpen));
  }

  mounted(): void {
    this.emitter.on("drawer-tags-open", async (state: boolean) => {
      this.isOpen = state;
    });
  }
}
