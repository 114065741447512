
import { Options, Vue } from "vue-class-component";
import {
  assetsModule,
  usersModule,
} from "@/store";
import {
  AssetsInterface,
} from "@/types";
import { PropType } from "vue";

@Options({
  name: "PinnedAssetModal",
  props: {
    pinnedAssetData: {
      type: Object as PropType<AssetsInterface>
    },
  },
})
export default class PinnedAssetModal extends Vue {
  protected unpin(assetId: string): void {
    if (!assetId) return;

    assetsModule.UnpinAsset(assetId);
    this.$emit("on-close");
  }
}
