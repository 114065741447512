import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-76b4d934")
const _hoisted_1 = { class: "icon-holder" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetAndGoToHome && _ctx.resetAndGoToHome(...args))),
    class: _normalizeClass(['link-to-home', _ctx.isHomeRoute && 'is-active'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_svg_icon, {
        class: "icon icon--medium",
        local: "",
        src: require('./images/home-icon.svg')
      }, null, 8, ["src"])
    ])
  ], 2))
}