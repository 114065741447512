import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-10b2981c")
const _hoisted_1 = { class: "media-types" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["for"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['asset-type-filter', _ctx.selectedAssetTypes.length && 'is-active'])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetTypes, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            id: item.id,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateSelection && _ctx.updateSelection(...args))),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedAssetTypes = $event)),
            value: item.id
          }, null, 40, _hoisted_2), [
            [_vModelCheckbox, _ctx.selectedAssetTypes]
          ]),
          _createElementVNode("label", {
            class: "checkbox-label",
            for: item.id
          }, _toDisplayString(item.label), 9, _hoisted_3)
        ]))
      }), 128))
    ])
  ], 2))
}