import { createStore } from "vuex";

import LanguagesModule from "@/store/modules/languages.store";
import SettingsModule from "@/store/modules/settings.store";
import MenuModule from "@/store/modules/menu.store";
import CategoriesModule from "@/store/modules/categories.store";
import AssetsDataModule from "@/store/modules/assets.store";
import UsersModule from "@/store/modules/users.store";

const store = createStore({});

export const languagesModule = new LanguagesModule({
  store,
  name: "languages",
});
export const menuModule = new MenuModule({ store, name: "menu" });
export const settingsModule = new SettingsModule({ store, name: "settings" });
export const categoriesModule = new CategoriesModule({
  store,
  name: "categories",
});
export const assetsModule = new AssetsDataModule({ store, name: "assets" });
export const usersModule = new UsersModule({
  store,
  name: "authentication",
});

export default store;
