import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-31ab0cc6")
const _hoisted_1 = { class: "drawer-inner" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = ["title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBtn = _resolveComponent("CloseBtn")!
  const _component_AlbumListFilter = _resolveComponent("AlbumListFilter")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["drawer", { 'drawer--opened': _ctx.isOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CloseBtn, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AlbumListFilter, {
          "show-tags": "",
          class: "tags-drawer-filter"
        }),
        _createElementVNode("span", {
          class: _normalizeClass([
            'pinned-content-link',
            !_ctx.hasAccessToPinnedAssets && 'is-disabled',
          ]),
          title: !_ctx.hasAccessToPinnedAssets ? 'Only registered users' : '',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlePinnedContentLink()))
        }, _toDisplayString(_ctx.$t("pinned_content")), 11, _hoisted_4)
      ])
    ])
  ], 2))
}