
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "ShopBtn",
  components: {
    SvgIcon,
  },
})
export default class ShopBtn extends Vue {
  private onClickAction(): void {
    this.$router.push({ path: "/shop" });
  }
}
