
import { mixins, Options } from "vue-class-component";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

const HOME_ROUTE = "3d-viewer/home";

@Options({
  name: "HomeButton",
  components: {
    SvgIcon,
  },
})
export default class HomeButton extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  protected async resetAndGoToHome(): Promise<void> {
    await this.redirectToRoute("/");
    this.emitter.emit("reset-all");
  }

  protected get isHomeRoute(): boolean {
    return this.$route.name === HOME_ROUTE;
  }
}
