import { openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5005c6c8")
const _hoisted_1 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    ref: "modelsIframe",
    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScanOnload && _ctx.onScanOnload(...args))),
    src: _ctx.model3DPath,
    allow: "fullscreen"
  }, "\n    Your browser doesn't support frames!\n  ", 40, _hoisted_1))
}