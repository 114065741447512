import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-24eedd42")
const _hoisted_1 = { class: "icon-holder" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.hasAccessToPinnedAssets ? 'div' : 'router-link'), {
    class: _normalizeClass(["link-to-pinned-assets", {
      'is-active': _ctx.isMyAssetsRoute,
      'is-disabled': !_ctx.hasAccessToPinnedAssets,
    }]),
    to: `/my-assets`,
    title: !_ctx.hasAccessToPinnedAssets ? 'Only registered users' : ''
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          class: "icon icon--medium",
          src: 'icons/pinned-assets-icon.svg'
        }, null, 8, ["src"])
      ])
    ]),
    _: 1
  }, 8, ["class", "title"]))
}