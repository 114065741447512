const settings = {
  fixedHeader: true,
  theme: {
    mode: {
      dark: true,
    },
  },
};

export default settings;
