
import { Vue, Options, mixins } from "vue-class-component";
import { TagsInterface, CategoriesInterface } from "@/types";
import { AlbumAndAssetManagementMixin } from "@/mixins";
import { assetsModule, categoriesModule } from "@/store";
import { ClickOutside } from "@/directives";
import TagsDropdown from "./TagsDropdown/index.vue";
import SelectedTags from "./SelectedTags/index.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "TagFilter",
  components: { TagsDropdown, SelectedTags, SvgIcon },
  directives: {
    "click-outside": ClickOutside,
  },
})
export default class TagFilter extends mixins(AlbumAndAssetManagementMixin) {
  protected isOpen = false;
  protected selectedTags: TagsInterface[] = [];
  protected selectedCategories: CategoriesInterface[] = [];

  protected get getCategories(): CategoriesInterface[] {
    return categoriesModule.getCategories;
  }

  protected get getTags(): TagsInterface[] {
    const filters = assetsModule.getFilters;
    const albums = assetsModule.getFilteredAlbums || [];
    const tags = assetsModule.getTags || [];

    if (filters.categories.length && albums.length) {
      const assets = this.extractNestedAssets(albums);
      const tagIdsInAssets: Set<string> = new Set(
        assets.flatMap((asset) => asset.tagIds)
      );
      return tags.filter((tag: TagsInterface) =>
        tagIdsInAssets.has(tag.id)
      );
    }

    return tags;
  }

  protected updateCategories(categories: CategoriesInterface[]): void {
    const filteredCategories = categories.map(
      (category: CategoriesInterface) => category.id
    );

    this.selectedCategories = categories;
    this.emitter.emit("reset-tags");

    this.$emit("update-categories", filteredCategories);
  }

  protected updateTags(tags: TagsInterface[]): void {
    const filteredTags = tags.map((tag: TagsInterface) => tag.id);

    this.selectedTags = tags;
    this.$emit("update-tags", filteredTags);
  }

  protected closeTags(): void {
    this.isOpen = false;
  }

  protected toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  mounted(): void {
    window.addEventListener("keydown", this.closeOnEsc);
  }

  beforeDestroy(): void {
    window.removeEventListener("keydown", this.closeOnEsc);
  }

  closeOnEsc(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.isOpen = false;
    }
  }
}
