
import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import { UserInterface } from "@/types";

const VUE_APP_MAIN_DOMAIN = process.env.VUE_APP_MAIN_DOMAIN;

@Options({
  name: "ButtonsComponent",
  props: {
    isPremiumExpired: {
      type: Boolean,
    },
    userData: {
      type: Object as PropType<Partial<UserInterface>>
    },
  },
})
export default class ButtonsComponent extends Vue {
  protected readonly userData!: Partial<UserInterface>;

  protected get getRenewPremiumPath(): string {
    if (!this.userData || !this.userData.subscriptionId) {
      return this.getMySubscriptionsPath;
    }

    return `${VUE_APP_MAIN_DOMAIN}/my-account/view-subscription/${this.userData.subscriptionId}`;
  }

  protected get getMySubscriptionsPath(): string {
    return `${VUE_APP_MAIN_DOMAIN}/my-account/subscriptions/`;
  }
}
