import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import {CategoriesInterface, FolderDataInterface, responseInterface} from "@/types";
import api from "@/apiClient";
import { languagesModule } from "@/store";

@Module
export default class CategoriesModule extends VuexModule {
  private globalLoader = false;
  private categories: CategoriesInterface[] = [];
  private active: CategoriesInterface = { id: "", name: "", title: "" };

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.categories
      .fetch(languagesModule.getActive.code)
      .then((response: responseInterface) => {
        if (response.statusCode !== 200) return;

        const data: CategoriesInterface[] = response.data;

        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.setCategories(data);
        this.setGlobalLoader(false);

        if (!this.getActive) {
          const activeDefault = this.getActiveDefault;
          this.UpdateActive(activeDefault);
        }
      });
  }

  @Action
  public UpdateActive(active: CategoriesInterface) {
    this.setActive(active);
  }

  @Mutation
  private setCategories(categories: CategoriesInterface[]) {
    this.categories = categories;
  }

  @Mutation
  private setActive(active: CategoriesInterface) {
    this.active = active;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getCategories() {
    const categories = this.categories;
    return categories.filter((c) => c.visible);
  }

  get getActive() {
    return this.active;
  }

  private get getActiveDefault(): CategoriesInterface {
    const [defaultCategory, ...rest] = this.getCategories;

    return defaultCategory;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
