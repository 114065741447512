
import { Options, Vue } from "vue-class-component";
import HomeFooterToolsDesktop from "./HomeFooterToolsDesktop/index.vue";
import HomeFooterToolsMobile from "./HomeFooterToolsMobile/index.vue";
import { useMq } from "vue3-mq";

const ALBUMS_ROUTE = "3d-viewer/albums";

@Options({
  name: "HomeComponentFooter",
  components: {
    HomeFooterToolsDesktop,
    HomeFooterToolsMobile,
  },
})
export default class HomeComponentFooter extends Vue {
  protected mq = useMq();

  protected get isAssetsView(): boolean {
    return this.$route.name !== ALBUMS_ROUTE;
  }
}
