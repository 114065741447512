import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2092100a")
const _hoisted_1 = { class: "drawer-inner" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlbumFilterAndDisplayComponent = _resolveComponent("AlbumFilterAndDisplayComponent")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["drawer", { 'drawer--opened': _ctx.isOpen }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AlbumFilterAndDisplayComponent, { "show-assetSearch": "" })
        ])
      ], 2))
    : _createCommentVNode("", true)
}