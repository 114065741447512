
import {Options, Vue} from "vue-class-component";

@Options({
  name: "MenuItemLink",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
})
export default class MenuItemLink extends Vue {
}
