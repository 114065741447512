
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "StoreBtn",
  props: {
    name: {
      type: String,
    },
    local: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SvgIcon,
  },
})
export default class DefaultBtn extends Vue {}
