
import { Options, Vue } from "vue-class-component";
import { AssetsInterface } from "@/types";
import Asset from "@/components/ui/BaseAlbumsWithAssets/AlbumList/TreeNode/index.vue"
import { PropType } from "vue";

@Options({
  name: "AssetComponent",
  components: { Asset },
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
      default: () => [],
    },
  },
})
export default class AssetComponent extends Vue {}
