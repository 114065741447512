
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { CategoriesInterface, TagsInterface } from "@/types";
import UnselectTagButton from "../UnselectTagButton/index.vue";

@Options({
  name: "SelectedTags",
  components: {
    UnselectTagButton,
  },
  props: {
    selectedTags: {
      type: Array as PropType<TagsInterface[] | CategoriesInterface[]>,
      default: () => [],
    },
  },
  watch: {
    selectedTags: {
      immediate: true,
      deep: true,
      handler(newValue: TagsInterface[] | CategoriesInterface[]) {
        this.localTags = [...newValue];
        const defaultTagExists = this.checkDefaultTag();

        if (!newValue.length && !defaultTagExists) {
          this.localTags.push({
            id: "default",
            name: "All content",
            default: true,
          });
        } else if (newValue.length && defaultTagExists) {
          this.localTags = this.localTags.filter(
            (tag: TagsInterface | CategoriesInterface) => tag.id !== "default"
          );
        }
      },
    },
  },
})
export default class SelectedTags extends Vue {
  protected localTags: TagsInterface[] & CategoriesInterface[] = [];

  protected isDefaultTag(tag: TagsInterface | CategoriesInterface): boolean {
    return tag.id === "default";
  }

  protected unselectTag(item: TagsInterface | CategoriesInterface): void {
    if (this.identifyCategoryType(item)) {
      this.emitter.emit("unselect-category");
    } else {
      this.emitter.emit("unselected-tags", item);
    }
  }

  protected checkDefaultTag(): boolean {
    return (
      this.localTags.findIndex(
        (tag: TagsInterface | CategoriesInterface) => tag.id === "default"
      ) !== -1
    );
  }

  private identifyCategoryType(item: any): item is CategoriesInterface {
    return "title" in item;
  }

  protected getTagName(item: TagsInterface | CategoriesInterface): string {
    return this.identifyCategoryType(item) ? item.title : item.name;
  }

  protected get showClearAllButton(): boolean {
    return !!this.localTags.find((t: any) => t.id !== "default");
  }
}
