import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logo" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", { href: _ctx.pathToHome }, [
      _createVNode(_component_Icon, {
        class: _normalizeClass(['icon', 'icon--logo']),
        src: 'logo.png',
        alt: 'Logo'
      }, null, 8, ["src"])
    ], 8, _hoisted_2)
  ]))
}