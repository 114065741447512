const config = {
  base: process.env.VUE_APP_MAIN_DOMAIN + "/",
  homepage: process.env.VUE_APP_DOMAIN,
  lang: ["en", "lv"],
  systemName: "3D Reference Tool | by Anatomy For Sculptors ®",
  supportEmail: "support@anatomy4sculptors.com",
  api_host: "/api",
  assets_path: "/assets",
  slider: {
    ratio: 1.4,
  },
  assets: [
    {
      id: 0,
      name: "albums",
      type: 3,
      title: "BaseSliderAlbums.vue",
      icon: "albums-icon",
      visible: true,
    },
  ],
  assetFilter: [
    { id: 1, label: "3D Models", key: "model" },
    { id: 2, label: "Images", key: "image" },
    { id: 3, label: "Videos", key: "video" },
  ],
  socials: [
    {
      filename: "facebook.svg",
      url: "https://www.facebook.com/anatomy4sculptors",
      name: "facebook",
    },
    {
      filename: "instagram.svg",
      url: "https://www.instagram.com/anatomy4sculptors",
      name: "instagram",
    },
    {
      filename: "pinterest.svg",
      url: "https://www.pinterest.com/Anatomy4Sculptors",
      name: "pinterest",
    },
    {
      filename: "artstation.svg",
      url: "https://www.artstation.com/anatomy4sculptors",
      name: "artstation",
    },
    {
      filename: "youtube.svg",
      url: "https://www.youtube.com/c/AnatomyForSculptors",
      name: "youtube",
    },
    {
      filename: "twitter.svg",
      url: "https://twitter.com/A4Sculptors",
      name: "twitter",
    },
  ],
};

export default config;
