
import { Options, Vue } from "vue-class-component";
import { FoldersWithAssetsInterface } from "@/types";
import TreeNode from "../TreeNode/index.vue";
import { PropType } from "vue";

@Options({
  name: "Tree",
  components: { TreeNode },
  props: {
    treeData: {
      type: Array as PropType<FoldersWithAssetsInterface[]>,
    },
    activeAlbum: {
      type: Object as PropType<FoldersWithAssetsInterface | null>,
      default: null,
    },
    pathIds: {
      type: Array as PropType<Array<string>>,
    },
    depth: {
      type: Number,
    },
  },
  emits: ["album-click"],
})
export default class Tree extends Vue {
  private pathIds!: string[];
  protected treeData!: FoldersWithAssetsInterface[];

  protected hasSubAlbum(node: any): boolean {
    return node.folders && node.folders.length > 0;
  }

  protected hasAssets(node: any): boolean {
    return node.assets && node.assets.length > 0;
  }

  protected isActive(nodeSlug: string): boolean {
    const { albumPath } = this.$route.params;
    return albumPath?.includes(nodeSlug);
  }

  protected isOpen(nodeSlug: string): boolean {
    return this.pathIds?.includes(nodeSlug);
  }
}
