import { urlsInterface } from "@/types";
import {
  LanguagesApiClientInterface,
  LanguagesApi,
} from "@/apiClient/models/api/language/languageApiClient.models";

const urls: urlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/en/languages`,
  post: "/path/to/post/language",
};

const languagesApiClient: LanguagesApiClientInterface = new LanguagesApi(urls);

export default languagesApiClient;
