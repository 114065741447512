
import { Options, Vue } from "vue-class-component";
import MenuItemButton from "./MenuItemButton/index.vue";
import MenuItemLink from "./MenuItemLink/index.vue";

@Options({
  name: "HeaderMenuItem",
  components: { MenuItemButton, MenuItemLink },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
})
export default class HeaderMenuItem extends Vue {}
