import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickAction()))
  }, [
    _createVNode(_component_svg_icon, {
      class: "icon-cart",
      src: `icons/icon-cart-outline.svg`
    }, null, 8, ["src"])
  ]))
}