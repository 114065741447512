import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3e58f792")
const _hoisted_1 = { class: "albums" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree")!
  const _component_NewestAlbum = _resolveComponent("NewestAlbum")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tree, {
      "tree-data": _ctx.filteredAlbums,
      "active-album": _ctx.activeAlbum,
      depth: 0,
      "path-ids": _ctx.pathIds,
      onAlbumClick: _ctx.handleAlbumClick
    }, null, 8, ["tree-data", "active-album", "path-ids", "onAlbumClick"]),
    _createVNode(_component_NewestAlbum)
  ]))
}