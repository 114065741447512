import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-763244b2")
const _hoisted_1 = { class: "grid asset-viewer" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeComponentHeader = _resolveComponent("HomeComponentHeader")!
  const _component_HomeComponentSlider = _resolveComponent("HomeComponentSlider")!
  const _component_HomeComponentAssetFilterAndDisplay = _resolveComponent("HomeComponentAssetFilterAndDisplay")!
  const _component_HomeComponentFooter = _resolveComponent("HomeComponentFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["asset-viewer-content", {
        'asset-viewer-content--read-more-extended': _ctx.isReadMoreExpand,
      }])
    }, [
      (['xs', 'sm', 'md'].includes(_ctx.mq.current))
        ? (_openBlock(), _createBlock(_component_HomeComponentHeader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_HomeComponentSlider),
      (['lg', 'xl', 'xxl'].includes(_ctx.mq.current))
        ? (_openBlock(), _createBlock(_component_HomeComponentAssetFilterAndDisplay, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_HomeComponentFooter)
    ], 2)
  ]))
}