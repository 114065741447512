import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7b768773")
const _hoisted_1 = {
  key: 0,
  class: "menu"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_header_menu_item = _resolveComponent("header-menu-item")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (i) => {
          return (_openBlock(), _createBlock(_component_header_menu_item, {
            item: i,
            key: i.name
          }, {
            item: _withCtx(({ link, title }) => [
              _createVNode(_component_router_link, { to: link }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["item"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}