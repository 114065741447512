import { Vue } from "vue-class-component";
import { AssetsInterface, FolderDataInterface } from "@/types";
import { assetsModule } from "@/store";

export default class RoutingUtilityMixin extends Vue {
  protected get getAlbums(): FolderDataInterface[] {
    return assetsModule.getAlbums;
  }

  private findAlbum(
    albums: FolderDataInterface[],
    key: keyof FolderDataInterface,
    value: string | null
  ): FolderDataInterface | undefined {
    return albums.find((album) => album[key] === value);
  }

  private constructPath(
    initialAlbumKey: keyof FolderDataInterface,
    initialAlbumValue: string | null,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    if (!fields || !fields.length) return [];

    const albums = this.getAlbums;
    const foundFields: Array<{ [key: string]: string }> = [];
    let currentAlbum = this.findAlbum(albums, initialAlbumKey, initialAlbumValue);

    while (currentAlbum) {
      const fieldObject: { [key: string]: string } = {};
      fields.forEach((field) => {
        if (currentAlbum && currentAlbum[field]) {
          fieldObject[field as string] = currentAlbum[field] as string;
        }
      });
      foundFields.unshift(fieldObject);
      currentAlbum = this.findAlbum(albums, 'id', currentAlbum.parentId as string);
    }

    return foundFields;
  }

  protected getAlbumPathBySlugs(
    slug: string,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    return this.constructPath("slug", slug, fields);
  }

  protected getAlbumPathByKey(
    asset: AssetsInterface | undefined,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    if (!asset) return [];
    return this.constructPath("id", asset.albumId, fields);
  }

  protected getFullAlbumsRouteByAlbumId(
    albumId: string | null,
    output: "array" | "string" = "array"
  ): string[] | string {
    const slugs = this.constructPath("id", albumId, ["slug"]).map(
      (o) => o.slug
    );
    return output === "string" ? `/albums/${slugs.join("/")}` : slugs;
  }

  protected getAlbumSlugPath(asset: AssetsInterface | undefined): string {
    const slugs = this.getAlbumPathByKey(asset, ["slug"])?.map(
      (o) => o.slug
    );
    return slugs.length ? `/albums/${slugs.join("/")}` : "";
  }

  protected getFullAssetRouteAsString(
    asset: AssetsInterface | undefined
  ): string {
    return `${this.getAlbumSlugPath(asset)}/assets/${asset?.slug}`;
  }

  protected getFullAssetRouteAsArray(
    asset: AssetsInterface | undefined
  ): string[] {
    if (!asset) return [];

    const albumNames = this.getAlbumPathByKey(asset, ["name"])?.map(
      (o) => o.name
    );

    return [...albumNames, asset.title];
  }

  protected getFullAssetRoute(
    asset: AssetsInterface | undefined,
    fields: Array<keyof FolderDataInterface>
  ): Array<{ [key: string]: string }> {
    if (!asset) return [];

    const albumPath = this.getAlbumPathByKey(asset, fields);
    albumPath.push({ name: asset.title, slug: asset.slug });
    return albumPath;
  }

  protected get getAlbumPathArray(): string[] | string {
    const albumPath: string[] | string = this.$route.params.albumPath || [];
    const assetsIndex = albumPath.indexOf("assets");
    const cutAlbumPath =
      assetsIndex !== -1 ? albumPath.slice(0, assetsIndex) : albumPath;

    return cutAlbumPath;
  }

  protected isRoutePathValid(
    routePath: string,
    asset: AssetsInterface | undefined
  ): boolean {
    const fullAssetPath = this.getFullAssetRouteAsString(asset);
    return routePath.trim() === fullAssetPath.trim();
  }

  protected redirectToRoute(route: string): Promise<any> {
    if (route !== this.$route.path) {
      return this.$router.push({
        path: route,
        query: {
          redirect_to: this.$route.query.redirect_to,
        },
      });
    } else {
      return Promise.resolve();
    }
  }

  protected redirectToAlbum(albumPath: string[] | string): void {
    const path =
      typeof albumPath === "string"
        ? `/albums/${albumPath}`
        : `/albums/${albumPath.join("/")}`;

    this.$router.push({ path: path });
  }
}
