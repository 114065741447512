
import { Options, Vue } from "vue-class-component";
import config from "@/config";

@Options({
  name: "HeaderMenuDropdown",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
})
export default class HeaderMenuDropdown extends Vue {
  protected fullPath(i: any): string {
    if (i.external) {
      if (i.link === "/") {
        return config.homepage + i.link;
      }

      return i.link;
    }

    return config.base + i.link;
  }
}
