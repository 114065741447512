
import { Vue, Options } from "vue-class-component";

@Options({
  name: "PremiumText",
  props: {
    isPremiumExpired: {
      type: Boolean,
    },
    isYearlyPlan: {
      type: Boolean,
    },
  },
})
export default class PremiumText extends Vue {
  private readonly isPremiumExpired!: boolean;
  private readonly isYearlyPlan!: boolean;

  protected get text(): string {
    const monthlyPrice =
      "Only $7.95/month to continue exploring all the 3D resources";
    const yearlyPrice =
      "Only $79.95/year to continue exploring all the 3D resources";

    if (!this.isPremiumExpired && this.isYearlyPlan) {
      return `See your access details and renewal date on your subscription page. <br> Stay ahead and keep your Premium content unlocked!`;
    }

    return this.isYearlyPlan ? yearlyPrice : monthlyPrice;
  }
}
