
import { Options, Vue } from "vue-class-component";
import AlbumFilterAndDisplayComponent from "@/components/ui/BaseAlbumFilterAndDisplayComponent/index.vue";
import HomeSliderAlbums from "@/views/Home/HomeComponentSlider/HomeSliderAlbums/index.vue";

@Options({
  name: "AssetsAndAlbumsDrawer",
  components: {
    AlbumFilterAndDisplayComponent,
    HomeSliderAlbums,
  },
})
export default class AssetsAndAlbumsDrawer extends Vue {
  protected isOpen = false;
  protected assetTypes: number[] = [];

  mounted(): void {
    this.emitter.on("drawer-open", (state: boolean) => {
      this.isOpen = state;
    });
  }

  beforeDestroy(): void {
    this.isOpen = false;
    this.emitter.off("drawer-open");
  }
}
