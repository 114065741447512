
import { Options, mixins } from "vue-class-component";
import { HeaderMixin } from "@/mixins";
import Logo from "@/components/ui/BaseIcons/Logo.vue";
import MenuBtn from "./BaseButtonHamburger/index.vue";
import HeaderMenu from "./TheHeaderMenu/index.vue";
import HeaderMetaInfo from "./TheHeaderMetaInfo/index.vue";
import StoreBtn from "./BaseButtonStore/index.vue";
import ShopBtn from "./BaseButtonShop/index.vue";
import AccountButton from "./BaseButtonAccount/index.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { useMq } from "vue3-mq";
import HeaderMenuMobile from "./TheHeaderMenuMobile/index.vue";

@Options({
  name: "headerComponent",
  components: {
    Logo,
    MenuBtn,
    ShopBtn,
    HeaderMenu,
    StoreBtn,
    AccountButton,
    SvgIcon,
    HeaderMetaInfo,
    HeaderMenuMobile,
  },
  watch: {
    isMobileMenuOpen(val) {
      this.emitter.emit("toggle-mobile-menu", val);
    },
  },
})
export default class HeaderComponent extends mixins(HeaderMixin) {
  protected mq = useMq();
  protected isMobileMenuOpen = false;

  private resizeHandler(): void {
    this.emitter.emit("toggle-mobile-menu", false);
  }

  beforeMount(): void {
    window.addEventListener("resize", this.resizeHandler);
  }

  mounted(): void {
    this.emitter.on("toggle-mobile-menu", (state: boolean) => {
      this.isMobileMenuOpen = state;
    });
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
