
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin, HasAccessToAssetMixin } from "@/mixins";
import CloseBtn from "@/components/ui/Buttons/BaseButtonClose.vue";
import AlbumListFilter from "@/components/ui/BaseAlbumFilterAndDisplayComponent/AlbumListFilter/index.vue";
import { watch } from "@vue/runtime-core";
import { AssetsInterface, FiltersInterface } from "@/types";
import { assetsModule } from "@/store";
import { getAvailableTags } from "@/utils/helpers";

@Options({
  name: "TagsAndPinnedAssetsButtonDrawer",
  components: {
    CloseBtn,
    AlbumListFilter,
  },
})
export default class TagsAndPinnedAssetsButtonDrawer extends mixins(
  RoutingUtilityMixin,
  HasAccessToAssetMixin
) {
  protected isOpen = false;

  protected close(): void {
    this.emitter.emit("drawer-tags-open", false);
  }

  protected async handlePinnedContentLink(): Promise<void> {
    if (!this.hasAccessToPinnedAssets) return;

    assetsModule.ClearFilters(["assetTypes"]);

    await this.redirectToRoute("/my-assets");
    this.emitter.emit("drawer-open", false);
    this.emitter.emit("drawer-tags-open", false);
  }

  protected get getFilters(): FiltersInterface {
    return assetsModule.getFilters;
  }

  protected get getAssets(): AssetsInterface[] {
    return assetsModule.getAssets;
  }

  protected setAvailableTags(tags: string[]): void {
    this.emitter.emit(
      "available-tags",
      getAvailableTags({
        assets: this.getAssets,
        tags: tags,
      })
    );
  }

  mounted(): void {
    this.emitter.on("drawer-tags-open", (state: boolean) => {
      this.isOpen = state;
    });

    watch(
      () => this.getFilters,
      async (filters) => {
        const filteredAlbums = await assetsModule.FilterAlbumsByCustomFilter(
          filters
        );

        assetsModule.setFilteredAlbums([...filteredAlbums]);
        this.setAvailableTags(filters.tags);
      }
    );
  }
}
