import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-221bebc1")
const _hoisted_1 = { class: "grid-item grid-item--assets" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlbumFilterAndDisplayComponent = _resolveComponent("AlbumFilterAndDisplayComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AlbumFilterAndDisplayComponent, {
      "show-panel": "",
      "show-assetSearch": "",
      "show-tags": ""
    })
  ]))
}