
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "UnselectTagButton",
  components: {
    SvgIcon,
  },
})
export default class UnselectTagButton extends Vue {}
