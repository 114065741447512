import {
  urlsInterface,
  responseInterface,
} from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/apiClient/models/http/httpClient.models";

export interface UserApiClientInterface {
  checkAccess: () => Promise<responseInterface>;
  wpAuthToken: () => Promise<responseInterface>;
}

export class UserApi implements UserApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  checkAccess(): Promise<responseInterface> {
    const GetParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: true,
      wp: true,
    };

    return httpClient.get<responseInterface>(GetParameters);
  }

  wpAuthToken(): Promise<responseInterface> {
    const GetParameters: HttpClientGetParameters = {
      url: this.urls.wpAuthToken as string,
      requiresToken: true,
      wp: true,
    };

    return httpClient.get<responseInterface>(GetParameters);
  }
}
