
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import HeaderMenuDropdown from "../../TheHeaderMenuDropdown/index.vue";

@Options({
  name: "MenuItemButton",
  components: { SvgIcon, HeaderMenuDropdown },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
})
export default class MenuItemButton extends Vue {}
