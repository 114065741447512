
import { Options, Vue } from "vue-class-component";
import ImageWithLoader from "@/components/ui/BaseImage/ImageWithLoader.vue";
import { usersModule } from "@/store";
import { useMq } from "vue3-mq";
import debounce from "debounce";

@Options({
  name: "AccessDenied",
  components: { ImageWithLoader },
  props: {
    asset: {
      type: Object,
      default() {
        return {};
      },
    },
    isModels: {
      type: Number,
    },
  },
  watch: {
    asset(val) {
      if (val) this.$nextTick(() => this.setBackgroundImage());
    },
  },
})
export default class AccessDenied extends Vue {
  $refs!: {
    canvas: HTMLFormElement;
    overlayInner: HTMLFormElement;
    imageHolder: HTMLFormElement;
  };

  protected mq = useMq();
  private basePath = process.env.VUE_APP_RESOURCE_URL;
  private asset!: any;
  private isModels!: number;
  protected newImageUrl = "";

  protected get isUserAuth(): boolean {
    return usersModule.IsAuthenticated;
  }

  protected get getPremiumLinkPath(): string {
    return process.env.VUE_APP_MAIN_DOMAIN + "/product/3d-viewer-premium/";
  }

  protected get getSignInLinkPath(): string {
    return process.env.VUE_APP_MAIN_DOMAIN + "/sign-in/?redirect_to=" + process.env.VUE_APP_DOMAIN;
  }

  protected get getPath(): string {
    try {
      if (this.asset.assetType === this.isModels) {
        return `${process.env.VUE_APP_WEB_VIEWER_CONTENT_URL}${this.asset.src}.preview.png`;
      }

      if (this.asset.src) {
        return `${this.basePath}/${this.asset.src}`;
      }

      return require(`@/assets/images/icons/broken-image.svg`);
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }

  protected get premiumText(): string {
    const loginText =
      "Get access to our growing 3D model and image reference library";
    const guestText = `
                        only <span>7.95 USD a month</span> and unlock our growing 3D model <br>
                        and image reference material library
                        `;
    return this.isUserAuth ? loginText : guestText;
  }

  protected setBackgroundImage(): void {
    if (this.asset.assetType === this.isModels) {
      this.newImageUrl = this.getPath;
      return;
    }

    // Create a new image element
    const image = new Image();
    // Set the image source with a cache-busting query parameter
    image.crossOrigin = "Anonymous";
    image.src = `${this.getPath}?not-from-cache-please`;

    image.onload = () => {
      // Get the height of the overlay inner
      const overlayInner = this.$refs.overlayInner;
      // Get references to the canvas element
      const canvas = this.$refs.canvas;

      if (!overlayInner) {
        return;
      }

      if (!canvas) {
        return;
      }

      const context = canvas.getContext("2d");

      // Get canvas element height
      const canvasHeight = canvas.offsetHeight;

      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Adjust canvas dimensions based on the aspect ratio
      if (aspectRatio > 1) {
        canvas.width = this.$refs.imageHolder.offsetWidth;
        canvas.height = this.$refs.imageHolder.offsetWidth / aspectRatio;
      } else {
        canvas.width = this.$refs.imageHolder.offsetHeight * aspectRatio;
        canvas.height = this.$refs.imageHolder.offsetHeight;
      }

      // Draw the image on the canvas
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      const rectHeightInPercentage =
        (overlayInner.offsetHeight / canvasHeight) * 100;
      const rectHeight = (rectHeightInPercentage * canvasHeight) / 100;
      const rectTop = (canvas.height - rectHeight) / 2;

      context.fillStyle = "#111113";
      context.fillRect(0, rectTop, canvas.width, Math.floor(rectHeight));
    };
  }

  mounted(): void {
    this.$nextTick(() => this.setBackgroundImage());

    window.addEventListener(
      "resize",
      debounce(() => {
        this.setBackgroundImage();
      }, 200)
    );
  }
}
