import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YourPremiumStatusModal = _resolveComponent("YourPremiumStatusModal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid-item grid-item--viewer", { 'modal-open': _ctx.isModalOpen }])
  }, [
    _createVNode(_component_YourPremiumStatusModal, { onUpdateModalState: _ctx.handleModalState }, null, 8, ["onUpdateModalState"]),
    _createVNode(_component_router_view)
  ], 2))
}