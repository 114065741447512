import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import {
  FolderDataInterface,
  MenuInterface,
  responseInterface,
  SubMenuDataInterface,
} from "@/types";
import api from "@/apiClient";
import { languagesModule } from "@/store";

@Module
export default class MenuModule extends VuexModule {
  private menu: MenuInterface[] = [];
  private globalLoader = false;

  @Action
  public Load(): void {
    api.menu
      .fetch(languagesModule.getActive.code)
      .then((response: responseInterface) => {
        if (response.statusCode !== 200) return;

        const data: MenuInterface[] = response.data;

        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.LoadSubMenu(data);
      });
  }

  @Action
  private LoadSubMenu(menu: MenuInterface[]): void {
    api.menu.fetchSubMenu().then((subMenu: SubMenuDataInterface) => {
      this.setMenu({ menu, subMenu });
    });
  }

  @Mutation
  private setMenu({
    menu,
    subMenu,
  }: {
    menu: MenuInterface[];
    subMenu: SubMenuDataInterface;
  }) {
    const subMenusMockup: SubMenuDataInterface = subMenu;

    menu = menu.map((m) => {
      if (subMenusMockup[m.name as keyof SubMenuDataInterface]) {
        m = {
          ...({
            child: subMenusMockup[m.name as keyof SubMenuDataInterface],
          } as MenuInterface),
          ...m,
        };
      }

      return m;
    });

    this.menu = menu;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getMenu() {
    return this.menu;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
