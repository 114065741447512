import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-73a9d44a")
const _hoisted_1 = {
  class: "slider",
  ref: "slider"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderArrow = _resolveComponent("SliderArrow")!
  const _component_Model3DComponent = _resolveComponent("Model3DComponent")!
  const _component_ImageComponent = _resolveComponent("ImageComponent")!
  const _component_VideoComponent = _resolveComponent("VideoComponent")!
  const _component_ToggleAssetPinButton = _resolveComponent("ToggleAssetPinButton")!
  const _component_AccessDeniedComponent = _resolveComponent("AccessDeniedComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isRouteValid && !_ctx.dataLoadingInProgress)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_SliderArrow, {
            direction: _ctx.direction.Left,
            assets: _ctx.getAssetsData
          }, null, 8, ["direction", "assets"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.hasAccessToAsset(_ctx.getAsset))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.getAsset!.assetType === _ctx.isModels)
                      ? (_openBlock(), _createBlock(_component_Model3DComponent, {
                          key: 0,
                          asset: _ctx.getAsset
                        }, null, 8, ["asset"]))
                      : _createCommentVNode("", true),
                    (_ctx.getAsset!.assetType === _ctx.isImage)
                      ? (_openBlock(), _createBlock(_component_ImageComponent, {
                          key: 1,
                          asset: _ctx.getAsset
                        }, null, 8, ["asset"]))
                      : _createCommentVNode("", true),
                    (_ctx.getAsset!.assetType === _ctx.isVideo)
                      ? (_openBlock(), _createBlock(_component_VideoComponent, {
                          key: 2,
                          asset: _ctx.getAsset
                        }, null, 8, ["asset"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ToggleAssetPinButton, { asset: _ctx.getAsset }, null, 8, ["asset"])
                  ], 64))
                : (_ctx.showAccessDeniedComponent)
                  ? (_openBlock(), _createBlock(_component_AccessDeniedComponent, {
                      key: 1,
                      asset: _ctx.getAsset,
                      "is-models": _ctx.isModels
                    }, null, 8, ["asset", "is-models"]))
                  : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_SliderArrow, {
            direction: _ctx.direction.Right,
            assets: _ctx.getAssetsData
          }, null, 8, ["direction", "assets"])
        ], 64))
      : _createCommentVNode("", true)
  ], 512))
}