
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { videoUrlParser } from "@/utils";
import { AssetsInterface } from "@/types";

@Options({
  name: "VideoComponent",
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
})
export default class VideoComponent extends Vue {
  protected getVideoPath(src: string): string {
    const result = videoUrlParser(src);

    if (result.platform === "vimeo") {
      return `${process.env.VUE_APP_VIMEO_VIDEO_EMBED_URL}${result.videoId}?autoplay=0&muted=1`;
    } else {
      return `${process.env.VUE_APP_YOUTUBE_VIDEO_EMBED_URL}${result.videoId}?autoplay=0&controls=1&rel=0&showinfo=0&mode=opaque`;
    }
  }
}
