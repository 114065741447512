import { Vue } from "vue-class-component";

export default class NoScrollMixin extends Vue {
  protected preventScrollInElement(el: HTMLElement): void {
    el.addEventListener(
      "wheel",
      (e: WheelEvent) => {
        e.preventDefault();
      },
      { passive: false }
    );

    el.addEventListener(
      "touchmove",
      (e: TouchEvent) => {
      e.preventDefault();
      },
      { passive: false }
    );
  }
}