import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-24425376")
const _hoisted_1 = ["title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'pin-asset-button',
      _ctx.isPinnedAssetsLoading && 'is-loading',
      _ctx.isPinned && 'is-pinned',
      !_ctx.hasAccessToAsset && 'is-disabled',
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePin(_ctx.asset, _ctx.isPinned))),
    title: _ctx.buttonTitle(_ctx.hasAccessToAsset, _ctx.isPinned)
  }, [
    _createVNode(_component_svg_icon, {
      class: "icon icon--medium",
      src: 'icons/pinned-assets-icon.svg'
    }, null, 8, ["src"])
  ], 10, _hoisted_1))
}