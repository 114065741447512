import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7273ad4a")
const _hoisted_1 = { class: "album-filter-list-holder" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlbumListFilter = _resolveComponent("AlbumListFilter")!
  const _component_AlbumList = _resolveComponent("AlbumList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AlbumListFilter, {
      ref: "albumListFilter",
      "show-panel": _ctx.showPanel,
      "show-asset-search": _ctx.showAssetSearch,
      "show-tags": _ctx.showTags
    }, null, 8, ["show-panel", "show-asset-search", "show-tags"]),
    _createVNode(_component_AlbumList, { ref: "albumList" }, null, 512)
  ]))
}