import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", null, [
    (_ctx.local)
      ? _renderSlot(_ctx.$slots, "svgIcon", { key: 0 })
      : (_openBlock(), _createBlock(_component_svg_icon, {
          key: 1,
          class: "icon icon--medium",
          src: `icons/${_ctx.name}.svg`
        }, null, 8, ["src"]))
  ]))
}