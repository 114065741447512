import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-593b4608")
const _hoisted_1 = { class: "album-list-filter-wrapper" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetManagementPanel = _resolveComponent("AssetManagementPanel")!
  const _component_AssetSearchByKeyFilter = _resolveComponent("AssetSearchByKeyFilter")!
  const _component_TagsFilter = _resolveComponent("TagsFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showPanel)
      ? (_openBlock(), _createBlock(_component_AssetManagementPanel, {
          key: 0,
          onUpdateAssetTypes: _ctx.updateAssetTypes
        }, null, 8, ["onUpdateAssetTypes"]))
      : _createCommentVNode("", true),
    (_ctx.showAssetSearch)
      ? (_openBlock(), _createBlock(_component_AssetSearchByKeyFilter, {
          key: 1,
          onUpdateSearchQuery: _ctx.updateSearchQuery
        }, null, 8, ["onUpdateSearchQuery"]))
      : _createCommentVNode("", true),
    (_ctx.showTags)
      ? (_openBlock(), _createBlock(_component_TagsFilter, {
          key: 2,
          onUpdateTags: _ctx.updateTags,
          onUpdateCategories: _ctx.updateCategories
        }, null, 8, ["onUpdateTags", "onUpdateCategories"]))
      : _createCommentVNode("", true)
  ]))
}