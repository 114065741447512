
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import InlineSvg from "vue-inline-svg";

@Options({
  name: "Icon",
  components: {
    InlineSvg,
  },
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String as PropType<string>,
    },
  },
})
export default class Icon extends Vue {
  private src!: string;

  private get getPath() {
    return require(`@/assets/images/${this.src}`);
  }
}
