import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", null, [
    _createVNode(_component_svg_icon, {
      class: "icon",
      local: "",
      src: require(`./images/close-icon.svg`)
    }, null, 8, ["src"])
  ]))
}