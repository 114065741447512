import {
  createRouter,
  createWebHistory,
  RouteRecordName,
  RouteRecordRaw,
} from "vue-router";
import config from "@/config";
import Home from "@/views/Home/index.vue";
import { assetsModule } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "3d-viewer/home",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/Home/HomeComponentSlider/HomeSliderAlbums/index.vue"
          ),
      },
      {
        path: "/albums",
        name: "3d-viewer/albums/root",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/Home/HomeComponentSlider/HomeSliderAlbums/index.vue"
          ),
      },
      {
        path: "/:section(albums)?/:albumPath*",
        name: "3d-viewer/albums",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/Home/HomeComponentSlider/HomeSliderAlbums/index.vue"
          ),
      },
      {
        path: "/albums/:albumPath*/assets/:assetSlug",
        name: "3d-viewer/albums/asset",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/Home/HomeComponentSlider/HomeSliderAssets/index.vue"
          ),
      },
      {
        path: "/my-assets",
        name: "3d-viewer/pinnedAssets",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/Home/HomeComponentSlider/HomeSliderPinnedAssets/index.vue"
          ),
      },
    ],
  },
  {
    path: "/shop",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "shop";
    },
  },
  {
    path: "/cart",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "cart";
    },
  },
  {
    path: "/info",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false;
    },
  },
  {
    path: "/3d-viewer",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false;
    },
  },
  {
    path: "/languages",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "languages";
    },
  },
  {
    path: "/books",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false;
    },
  },
  {
    path: "/products",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false;
    },
  },
  {
    path: "/articles",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "articles";
    },
  },
  {
    path: "/hpc",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = "https://hpc.anatomy4sculptors.com";
    },
  },
  {
    path: "/videos",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "videos";
    },
  },
  {
    path: "/pages/about-us-1",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "pages/about-us-1";
    },
  },
  {
    path: "/pages/contact-us",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "pages/contact-us";
    },
  },
  {
    path: "/reviews",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "reviews";
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackPrefetch: true */ "@/views/Page404/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
