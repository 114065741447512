
import { Options, Vue } from "vue-class-component";
import Icon from "@/components/ui/BaseIcons/Icon.vue";

@Options({
  name: "Logo",
  components: {
    Icon,
  },
})

export default class Logo extends Vue {
  private pathToHome = process.env.VUE_APP_MAIN_DOMAIN;
}
