import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-e9dd68c4")
const _hoisted_1 = { class: "asset-type-filter" }
const _hoisted_2 = { class: "media-types" }
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = ["for"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetTypes, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            id: item.id,
            class: _normalizeClass([
            _ctx.isDisabledInPinnedAssetsRoute && 'disabled-all-class',
            _ctx.isDisabledInAlbumsRoute && 'disabled-all-class',
          ]),
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelectionChange && _ctx.handleSelectionChange(...args))),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedAssetTypes = $event)),
            value: item.id
          }, null, 42, _hoisted_3), [
            [_vModelCheckbox, _ctx.selectedAssetTypes]
          ]),
          _createElementVNode("label", {
            class: _normalizeClass(['checkbox-label', `checkbox-label--${item.key}`]),
            for: item.id
          }, [
            _createVNode(_component_svg_icon, {
              class: "icon icon--medium",
              local: "",
              src: require(`./images/${item.key}-icon.svg`)
            }, null, 8, ["src"])
          ], 10, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}