export enum Direction {
  Left = -1,
  Right = 1,
}

export enum AssetType {
  Models = 1,
  Image = 2,
  Video = 3,
}

export enum TabsTypeString {
  Models = "3d_models",
  Images = "images",
  Albums = "albums",
}

export enum TabsTypeNumber {
  Models = 1,
  Images = 2,
  Albums = 3,
}

export enum Lang {
  Key = "_ec-locale",
}

export enum AccessTypes {
  Guest,
  Registered,
  Paid,
}
