import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7fd1a824")
const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "assets" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeNode = _resolveComponent("TreeNode")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['album', _ctx.isOpen && 'is-open'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "title",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
      }, "Newest"),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNewestAssets, (asset) => {
          return (_openBlock(), _createBlock(_component_TreeNode, {
            key: asset.id,
            asset: asset
          }, null, 8, ["asset"]))
        }), 128))
      ])
    ])
  ], 2))
}