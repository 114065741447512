import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-cc1a4926")
const _hoisted_1 = { class: "image-holder" }
const _hoisted_2 = { class: "title-wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "sub-title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageWithLoader = _resolveComponent("ImageWithLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", {
      'item--active': _ctx.asset && _ctx.isActive(_ctx.asset),
      'item--no-access': _ctx.asset && !_ctx.hasAccessToAsset(_ctx.asset),
      'item--check-access':
        _ctx.asset && !_ctx.hasAccessToAsset(_ctx.asset) && _ctx.isLoadingUserAccess,
    }])
  }, [
    _createVNode(_component_router_link, {
      to: { path: _ctx.getFullAssetRouteAsString(_ctx.asset) },
      onClick: _withModifiers(_ctx.onSelect, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ImageWithLoader, {
            ref: "image",
            src: _ctx.assetMediaUrl,
            d3model: _ctx.asset?.assetType === _ctx.isModels,
            video: _ctx.asset?.assetType === _ctx.isVideo,
            alt: _ctx.asset?.src
          }, null, 8, ["src", "d3model", "video", "alt"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.asset.title), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getAssetsTypesAsString([_ctx.asset.assetType])), 1)
        ])
      ]),
      _: 1
    }, 8, ["to", "onClick"])
  ], 2))
}