import { urlsInterface } from "@/types";
import {
  UserApiClientInterface,
  UserApi,
} from "@/apiClient/models/api/user/userApiClient.models";

const urls: urlsInterface = {
  fetch: process.env.VUE_APP_USER + "/users/check-access",
  wpAuthToken: process.env.VUE_APP_USER + "/users/validate-auth-token",
};

const userApiClient: UserApiClientInterface = new UserApi(urls);

export default userApiClient;
