
import { Options, Vue } from "vue-class-component";
import InlineSvg from "vue-inline-svg";

@Options({
  name: "SvgIcon",
  components: {
    InlineSvg,
  },
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    global: {
      type: Boolean,
      default: false,
    },
    local: {
      type: Boolean,
      default: false,
    },
  },
})
export default class SvgIcon extends Vue {
  private basePath = process.env.VUE_APP_RESOURCE_URL;
  private src!: string;
  private global!: boolean;
  private local!: boolean;
  private isLoaded = false;
  private isError = false;

  get getPath() {
    if (this.global) {
      if (this.isError || !this.src) {
        return require(`@/assets/images/icons/broken-image.svg`);
      }

      return `${this.basePath}/${this.src}`;
    }

    if (this.local) {
      return this.src;
    }

    try {
      return require(`@/assets/images/${this.src}`);
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }

  private onImageLoad(): void {
    this.isLoaded = true;
  }

  private onImageLoadError(): void {
    this.isError = true;
  }
}
