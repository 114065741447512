import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["menu-btn", {'menu-btn--close': _ctx.isActive}])
  }, [
    _createVNode(_component_svg_icon, {
      class: "icon ",
      src: `icons/${_ctx.iconName}.svg`
    }, null, 8, ["src"])
  ], 2))
}