
import { Vue, Options } from "vue-class-component";
import SearchInput from "./SearchInput/index.vue";
import debounce from "debounce";

@Options({
  name: "AssetSearchByKeyFilter",
  components: { SearchInput },
})
export default class AssetSearchByKeyFilter extends Vue {
  protected searchQuery = "";

  protected updateQuery = debounce((event: any) => {
    this.searchQuery = (event.target as HTMLInputElement).value;

    if (this.searchQuery.length > 2) {
      this.$emit("update-search-query", this.searchQuery);
    } else {
      this.clearInput();
    }
  }, 300);

  protected clearInput(): void {
    this.searchQuery = "";
    this.$emit("update-search-query", this.searchQuery);
  }

  mounted(): void {
    this.emitter.on("reset-all", this.clearInput);
  }
}
