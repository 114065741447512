import { SettingsInterface, urlsInterface } from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/apiClient/models/http/httpClient.models";

export interface SettingsApiClientInterface {
  fetch: () => Promise<SettingsInterface>;
}

export class SettingsApi implements SettingsApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  fetch(): Promise<SettingsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: false,
    };

    return httpClient.get<SettingsInterface>(getParameters);
  }
}
