
import { Options, Vue } from "vue-class-component";
import HomeFooterAssetDescription from "../HomeFooterDescription/index.vue";
import Breadcrumbs from "@/components/ui/BaseBreadcrumbs/index.vue";

const ASSETS_ROUTE = "3d-viewer/albums/asset";

@Options({
  name: "HomeFooterToolsDesktop",
  components: { HomeFooterAssetDescription, Breadcrumbs },
})
export default class HomeFooterToolsDesktop extends Vue {
  private isReadMoreExpand = false;
  protected descriptionAvailable = false;
  protected isDescriptionAvailable = false;

  protected get isAssetsRoute(): boolean {
    return this.$route.name === ASSETS_ROUTE;
  }

  protected get getReadMoreKey(): string {
    return this.isReadMoreExpand ? "read_less" : "read_more";
  }

  protected toggleDescription(): void {
    this.emitter.emit(
      "asset-description-toggle",
      (this.isReadMoreExpand = !this.isReadMoreExpand)
    );
  }

  protected handleDescriptionStatus(status: boolean): void {
    this.descriptionAvailable = status;
  }

  mounted(): void {
    this.emitter.on("asset-description-toggle", (state: boolean) => {
      this.isReadMoreExpand = state;
    });
  }
}
