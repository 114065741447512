
import { mixins, Options } from "vue-class-component";
import { assetsModule } from "@/store";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import {
  AssetTypeInterface,
  FoldersWithAssetsInterface,
  FiltersInterface,
} from "@/types";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import config from "@/config";

const PINNED_ASSETS_ROUTE = "3d-viewer/pinnedAssets";
const ALBUMS_ROUTE = "3d-viewer/albums";

@Options({
  name: "AssetTypeFilter",
  components: {
    SvgIcon,
  },
  watch: {
    isDisabledInPinnedAssetsRoute(val) {
      if (val) {
        this.selectedAssetTypes = [];
      }
    },
  },
})
export default class AssetTypeFilter extends mixins(
  AlbumAndAssetManagementMixin,
  RoutingUtilityMixin
) {
  protected assetTypes: AssetTypeInterface[] = [...config.assetFilter];
  protected selectedAssetTypes: number[] = [];

  protected get isDisabledInPinnedAssetsRoute(): boolean {
    return this.$route.name === PINNED_ASSETS_ROUTE;
  }

  protected get isDisabledInAlbumsRoute(): boolean {
    return this.$route.name === ALBUMS_ROUTE;
  }

  protected get isAlbumsRoute(): boolean {
    return this.$route.name === ALBUMS_ROUTE;
  }

  private async filteredAlbums(
    filters: FiltersInterface
  ): Promise<FoldersWithAssetsInterface[]> {
    return await assetsModule.FilterAlbumsByCustomFilter(filters);
  }

  private async redirectToAvailableAlbumPath(
    filters: FiltersInterface
  ): Promise<void> {
    const filteredAlbums = await this.filteredAlbums(filters);
    const [firstAsset] = this.extractNestedAssets(filteredAlbums);
    const redirectPath = filters.assetTypes.length
      ? this.getAlbumSlugPath(firstAsset)
      : "/";

    await this.redirectToRoute(redirectPath);
  }

  protected async handleSelectionChange(event: any): Promise<void> {
    this.selectedAssetTypes = this.selectedAssetTypes.filter(
      (type: number) => type === Number(event.target.value)
    );

    const filterObject: FiltersInterface = {
      ...assetsModule.getFilters,
      ...{ assetTypes: this.selectedAssetTypes },
    };

    assetsModule.Filters(filterObject);
    await this.redirectToAvailableAlbumPath(assetsModule.getFilters);
    this.$emit("handle-asset-type-change", this.selectedAssetTypes);
  }

  mounted(): void {
    this.emitter.on("asset-item-is-selected", () => {
      this.selectedAssetTypes = [];
    });

    this.emitter.on("reset-all-mobile", () => {
      this.selectedAssetTypes = [];
    });

    this.emitter.on("toggle-mobile-menu", async (val: boolean) => {
      if (val && this.isAlbumsRoute) {
        this.selectedAssetTypes = [];
        await this.redirectToRoute("/");
      }
    });
  }

  unmounted(): void {
    this.emitter.off("asset-item-is-selected");
  }
}
