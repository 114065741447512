import {CategoriesInterface, responseInterface, urlsInterface} from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
  HttpClientPostParameters,
} from "@/apiClient/models/http/httpClient.models";

export interface CategoriesApiClientInterface {
  fetch: (lang?: string) => Promise<responseInterface>;
}

export class CategoriesApi implements CategoriesApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  fetch(lang?: string): Promise<responseInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch + (lang ? `?locale=${lang}` : ""),
      requiresToken: false,
    };

    return httpClient.get<responseInterface>(getParameters);
  }
}
