import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import {
  SettingsInterface,
  ActiveInterface,
  AssetFolderInterface,
  AssetsTypesInterface,
} from "@/types";
import { copy } from "@/utils";
import { assetsModule, settingsModule } from "@/store";
import api from "@/apiClient";
import config from "@/config";

@Module
export default class SettingsModule extends VuexModule {
  private tabsLoader = false;
  private settings: AssetsTypesInterface[] = [
    {
      id: 0,
      name: "",
      type: 0,
      title: "",
      icon: "",
      visible: true,
    },
  ];
  private active: ActiveInterface = {
    assets: {
      id: 0,
      name: "",
      type: 0,
      title: "",
    },
  };

  @Action
  public Load(): void {
    this.setTabsLoader(true);

    api.settings.fetch().then((data: SettingsInterface) => {
      if (!data) {
        throw Error("Data must be a object!");
      }

      this.setSettings(data.assets_types);
      this.setTabsLoader(false);

      if (!Object.values(this.getActive.assets).filter(Boolean).length) {
        const activeDefault = this.getActiveDefault;
        this.Active(activeDefault);
      }
    });
  }

  @Action
  public Active(tab: ActiveInterface | null) {
    if (tab && settingsModule.getActive.assets.name === tab!.assets.name) return;

    this.setActive(tab);
  }

  @Action
  public Reset() {
    const activeDefault = this.getActiveDefault;
    this.Active(activeDefault);
  }

  @Mutation
  private setTabsLoader(loader: boolean) {
    this.tabsLoader = loader;
  }

  @Mutation
  private setSettings(settings: AssetsTypesInterface[]) {
    this.settings = settings;
  }

  @Mutation
  private setActive(active: ActiveInterface | null) {
    if (!active) {
      this.active = {
        assets: {
          id: 0,
          name: "",
          type: 0,
          title: "",
        }
      };
    } else {
      this.active = { ...this.active, ...active };
    }
  }

  get getTabsLoader(): boolean {
    return this.tabsLoader;
  }

  private get getActiveDefault(): ActiveInterface {
    const [defaultSettings, ...rest] = this.getSettings;

    return {
      assets: defaultSettings,
    };
  }

  get getActive(): ActiveInterface {
    return this.active;
  }

  get getSettings(): AssetsTypesInterface[] {
    let albums: AssetsTypesInterface[] = config.assets;

    let tabs = copy(this.settings).filter(
      (tab: AssetsTypesInterface) => tab.visible
    );

    if (tabs.length) {
      albums = albums.map((tab: AssetsTypesInterface) => {
        tab.id = tabs.length + 1;
        return tab;
      });

      tabs = [...albums, ...tabs];
    }

    return tabs;
  }
}
