import { urlsInterface } from "@/types";
import {
  SettingsApiClientInterface,
  SettingsApi,
} from "@/apiClient/models/api/settings/settingsApiClient.models";

const urls: urlsInterface = {
  fetch: "/static/settingsData.json",
};

const settingsApiClient: SettingsApiClientInterface = new SettingsApi(urls);

export default settingsApiClient;
