import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showArrow(_ctx.direction))
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(['slider-arrow', _ctx.directionClass(_ctx.direction)]),
        to: _ctx.getAssetPath(_ctx.direction)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SvgIcon, {
            class: "icon icon--small",
            src: `icons/arrow.svg`
          }, null, 8, ["src"])
        ]),
        _: 1
      }, 8, ["class", "to"]))
    : _createCommentVNode("", true)
}