import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-53a58a08")
const _hoisted_1 = { class: "icon-holder" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/albums",
    class: _normalizeClass(['link-to-albums', _ctx.isAlbumsRoute && 'is-active'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          class: "icon icon--medium",
          src: `icons/albums-icon.svg`
        }, null, 8, ["src"])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}