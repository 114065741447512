
import { Options, setup, mixins } from "vue-class-component";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import { FoldersWithAssetsInterface } from "@/types";
import { assetsModule, usersModule } from "@/store";
import HomeComponentHeader from "./HomeComponentHeader/index.vue";
import HomeComponentAssetFilterAndDisplay from "./HomeComponentAssetFilterAndDisplay/index.vue";
import HomeComponentSlider from "./HomeComponentSlider/index.vue";
import HomeComponentFooter from "./HomeComponentFooter/index.vue";
import { useMeta } from "vue-meta";
import { useMq } from "vue3-mq";

const ALBUMS_ROUTE = "3d-viewer/albums";

@Options({
  name: "Home",
  components: {
    HomeComponentSlider,
    HomeComponentHeader,
    HomeComponentAssetFilterAndDisplay,
    HomeComponentFooter,
  },
  watch: {
    getDefaultAlbums(val) {
      if (this.isAlbumsRoute || this.isAssetRoute) {
        this.initDataByRoute();
      }
    },
  },
})
export default class Home extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  protected isReadMoreExpand = false;
  protected mq = useMq();
  protected meta = setup(() =>
    useMeta({
      title: "3D Reference Tool",
      description:
        "Explore our 3D Reference tool extensive library of 3D models, images, and videos! A useful and convenient way to always have the right reference.",
      og: {
        title: "3D Reference Tool",
        description:
          "Explore our 3D Reference tool extensive library of 3D models, images, and videos! A useful and convenient way to always have the right reference.",
      },
    })
  );

  protected get getDefaultAlbums(): FoldersWithAssetsInterface[] {
    return assetsModule.getFoldersWithAssets;
  }

  protected get isAlbumsRoute(): boolean {
    return this.$route.name === ALBUMS_ROUTE;
  }

  protected get isAssetRoute(): boolean {
    return !!this.$route.params.assetSlug;
  }

  protected initDataByRoute(): void {
    const { albumPath, assetSlug } = this.$route.params;
    const lastAlbumSegment = albumPath[albumPath.length - 1];
    const asset = this.findAssetBySlug(
      this.getDefaultAlbums,
      (assetSlug || lastAlbumSegment) as string
    );

    if (asset === undefined) {
      // If the asset is not found, redirect to album
      this.redirectToAlbum(albumPath);
    } else {
      // If the asset is found, move to its full path route - this corrects the album segment in URL
      if (!this.isRoutePathValid(this.$route.path, asset)) {
        const assetPath = this.getFullAssetRouteAsString(asset);
        this.redirectToRoute(assetPath);
      }
    }
  }

  private resetAssets(): void {
    this.emitter.emit("drawer-open", null);
  }

  private resetTools(): void {
    this.resetAssets();
  }

  beforeMount(): void {
    if (this.isAssetRoute && this.getDefaultAlbums.length) {
      this.initDataByRoute();
    }
  }

  mounted(): void {
    this.emitter.on("toggle-mobile-menu", (state: boolean) => {
      if (state) this.resetTools();
    });

    this.emitter.on("asset-description-toggle", (state: boolean) => {
      this.isReadMoreExpand = state;

      if (state) {
        setTimeout(
          () => window.scrollTo({ top: 200, behavior: "smooth" }),
          300
        );
      }
    });
  }
}
