
import { Vue, Options } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { AssetTypeInterface } from "@/types";
import config from "@/config";

@Options({
  name: "AssetTypeFilter",
  components: {
    SvgIcon,
  },
})
export default class AssetTypeFilter extends Vue {
  protected assetTypes: AssetTypeInterface[] = config.assetFilter;
  protected selectedAssetTypes: number[] = [];

  created(): void {
    this.$emit("update-asset-types", this.selectedAssetTypes);
  }

  private sortAssetTypes(assetTypes: number[]): number[] {
    const copiedAssetTypes = [...assetTypes];
    copiedAssetTypes.sort((a, b) => a - b);
    return copiedAssetTypes;
  }

  protected updateSelection(event: any): void {
    this.$emit(
      "update-asset-types",
      this.sortAssetTypes(this.selectedAssetTypes)
    );
  }

  mounted(): void {
    this.emitter.on("reset-all", () => {
      this.selectedAssetTypes = [];
      this.$emit("update-asset-types", this.selectedAssetTypes);
    });
  }
}
