import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-13b23b89")
const _hoisted_1 = { class: "asset-management-panel" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeButton = _resolveComponent("HomeButton")!
  const _component_PinnedAssetsButton = _resolveComponent("PinnedAssetsButton")!
  const _component_AssetTypeFilter = _resolveComponent("AssetTypeFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeButton),
    _createVNode(_component_PinnedAssetsButton),
    _createVNode(_component_AssetTypeFilter, { onUpdateAssetTypes: _ctx.updateAssetTypes }, null, 8, ["onUpdateAssetTypes"])
  ]))
}