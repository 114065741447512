import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asset-search-by-key-filter", [!_ctx.searchQuery.length && 'is-empty']])
  }, [
    _createVNode(_component_SearchInput, {
      value: _ctx.searchQuery,
      onInput: _ctx.updateQuery,
      type: "text",
      placeholder: "Search..."
    }, null, 8, ["value", "onInput"]),
    (_ctx.searchQuery.length)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args))),
          class: "asset-search-action-button"
        }, " ✕ "))
      : _createCommentVNode("", true)
  ], 2))
}