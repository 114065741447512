import { Vue } from "vue-class-component";
import {
  AssetsInterface,
  FolderDataInterface,
  FoldersWithAssetsInterface,
} from "@/types";

export default class AlbumAndAssetManagementMixin extends Vue {
  protected findNestedAlbumById(
    albumId: string,
    albums: FoldersWithAssetsInterface[]
  ): FoldersWithAssetsInterface | null {
    for (const album of albums) {
      if (album.id === albumId) {
        return album;
      }

      const found = this.findNestedAlbumById(albumId, album.folders);
      if (found) {
        return found;
      }
    }
    return null;
  }

  protected findNestedAlbumBySlug(
    slug: string,
    albums: FoldersWithAssetsInterface[]
  ): FoldersWithAssetsInterface | null {
    for (const album of albums) {
      if (album.slug === slug) {
        return album;
      }

      const found = this.findNestedAlbumBySlug(slug, album.folders);
      if (found) {
        return found;
      }
    }
    return null;
  }

  protected getCurrentAlbumId(
    albumPath: string[] | string,
    albumsData: any
  ): string | null {
    if (albumPath && albumPath.length > 0) {
      const foundAlbum = this.findNestedAlbumBySlug(
        albumPath[albumPath.length - 1],
        albumsData
      );
      return foundAlbum ? foundAlbum.id : null;
    }
    return null;
  }

  protected extractNestedAssets(
    albums: FoldersWithAssetsInterface[] | FolderDataInterface[]
  ): AssetsInterface[] {
    let assets: AssetsInterface[] = [];

    const extractAssets = (
      album: FoldersWithAssetsInterface | FolderDataInterface
    ) => {
      assets = [...assets, ...album.assets];
      album.folders.forEach(extractAssets);
    };

    albums.forEach(extractAssets);

    return assets;
  }

  public findAssetBySlug(
    albums: FoldersWithAssetsInterface[] | FolderDataInterface[],
    slugToFind: string
  ): AssetsInterface | undefined {
    let foundAsset: AssetsInterface | undefined;

    const findAsset = (
      albums: FoldersWithAssetsInterface[] | FolderDataInterface[]
    ) => {
      for (const album of albums) {
        const asset = album.assets.find(
          (asset: AssetsInterface) => asset.slug === slugToFind
        );
        if (asset) {
          foundAsset = asset;
          break;
        }
        album.folders && findAsset(album.folders);
        if (foundAsset) break;
      }
    };

    findAsset(albums);

    return foundAsset;
  }
}
