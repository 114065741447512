
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin } from "@/mixins";
import { PropType } from "vue";
import { AssetsInterface, Direction } from "@/types";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "SliderArrow",
  components: { SvgIcon },
  props: {
    assets: {
      type: Object as PropType<AssetsInterface[]>,
    },
    direction: {
      type: Number as PropType<Direction>,
      required: true,
    },
  },
})
export default class SliderArrow extends mixins(RoutingUtilityMixin) {
  private assets!: AssetsInterface[];

  protected showArrow(direction: number): boolean {
    return direction === -1
      ? this.assetIndex > 0
      : this.assetIndex < this.assets.length - 1;
  }

  protected directionClass(direction: number): string {
    return direction === -1 ? "slider-arrow--left" : "slider-arrow--right";
  }

  protected getAssetPath(direction: number): string {
    const asset = this.assets[this.assetIndex + direction];
    return this.getFullAssetRouteAsString(asset);
  }

  protected get assetIndex(): number {
    return this.assets.findIndex(
      (a) => a.slug === this.$route.params.assetSlug
    );
  }
}
