import { urlsInterface } from "@/types";
import {
  CategoriesApiClientInterface,
  CategoriesApi,
} from "@/apiClient/models/api/categories/categoriesApiClient.models";

const urls: urlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/en/categories`,
};

const categoriesApiClient: CategoriesApiClientInterface = new CategoriesApi(
  urls
);

export default categoriesApiClient;
