
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { TagsInterface, CategoriesInterface } from "@/types";
import UnselectTagButton from "../UnselectTagButton/index.vue";

@Options({
  name: "TagsDropdown",
  components: { UnselectTagButton },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      },
    },
    categories: {
      type: Array as PropType<CategoriesInterface[]>,
      default() {
        return [];
      },
    },
    tagsData: {
      type: Array as PropType<TagsInterface[]>,
      default() {
        return [];
      },
    },
  },
  watch: {
    tagsData(tags) {
      this.availableTags = tags.map((tag: TagsInterface) => tag.id);
    },
  },
})
export default class TagsDropdown extends Vue {
  protected tagsData!: TagsInterface[];
  protected selectedTags: TagsInterface[] = [];
  protected selectedCategories: CategoriesInterface[] = [];
  protected availableTags: string[] = this.tagsData.map(
    (tag: TagsInterface) => tag.id
  );

  private categoryIndex(item: CategoriesInterface): number {
    return this.selectedCategories.findIndex(
      (category: CategoriesInterface) => category.id === item.id
    );
  }

  protected toggleCategorySelect(item: CategoriesInterface): void {
    if (this.categoryIndex(item) === -1) {
      this.selectedCategories = [item];
    } else {
      this.selectedCategories = [];
    }

    this.updateCategories();
  }

  protected resetCategorySelection(): void {
    this.selectedCategories = [];
    this.updateCategories();
  }

  protected updateCategories(): void {
    this.$emit("update-categories", this.selectedCategories);
  }

  private tagIndex(item: TagsInterface): number {
    return this.selectedTags.findIndex(
      (tag: TagsInterface) => tag.id === item.id
    );
  }

  protected toggleTagSelect(item: TagsInterface): void {
    this.tagIndex(item) === -1 ? this.selectTag(item) : this.unselectTag(item);
  }

  protected selectTag(item: TagsInterface): void {
    this.selectedTags.push(item);
    this.updateTags();
  }

  protected unselectTag(item: TagsInterface): void {
    const tagIndex = this.tagIndex(item);

    this.selectedTags.splice(tagIndex, 1);
    this.updateTags();
  }

  protected resetTagSelection(): void {
    this.selectedTags = [];
    this.updateTags();
  }

  protected updateTags(): void {
    this.$emit("update-tags", this.selectedTags);
  }

  protected resetSelection(): void {
    this.resetCategorySelection();
    this.resetTagSelection();
  }

  mounted(): void {
    this.emitter.on("available-tags", (availableTags: string[]) => {
      if (availableTags.length) {
        this.availableTags = availableTags;
      }

      if (!availableTags.length || !this.selectedTags.length) {
        this.availableTags = this.tagsData.map((tag: TagsInterface) => tag.id);
      }
    });

    this.emitter.on("unselected-tags", (item: TagsInterface) =>
      this.unselectTag(item)
    );

    this.emitter.on("unselect-category", () => this.resetCategorySelection());

    this.emitter.on("reset-all", () => {
      this.resetCategorySelection();
      this.emitter.emit("reset-tags-and-categories");
    });

    this.emitter.on("reset-tags-and-categories", this.resetSelection);
    this.emitter.on("reset-tags", this.resetTagSelection);
  }
}
