import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0cd56a4d")
const _hoisted_1 = { class: "video-holder" }
const _hoisted_2 = ["src", "title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: _ctx.getVideoPath(_ctx.asset!.src),
      title: _ctx.asset?.title,
      frameborder: "0",
      allowfullscreen: ""
    }, "\n      Your browser doesn't support frames!\n    ", 8, _hoisted_2)
  ]))
}