
import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";

@Options({
  props: {
    value: {
      type: String,
      required: false,
    },
    description: {
      type: String,
    },
  },
})
export default class SearchInput extends Vue {
  protected value!: string;

  private get makeId(): string {
    return uuidv4();
  }
}
