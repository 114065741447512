
import { Options, Vue } from "vue-class-component";
import { assetsModule } from "@/store";
import { AssetTypeInterface } from "@/types";
import DefaultButton from "@/components/ui/Buttons/BaseButtonDefault.vue";
import AssetTypeFilter from "./AssetTypeFilter/index.vue";
import HomeButton from "./HomeButton/index.vue";
import TagsButton from "./TagsButton/index.vue";
import ReadMoreButton from "./ReadMoreButton/index.vue";
import HomeFooterAssetDescription from "../HomeFooterDescription/index.vue";
import config from "@/config";

@Options({
  name: "HomeFooterToolsMobile",
  components: {
    DefaultButton,
    AssetTypeFilter,
    HomeFooterAssetDescription,
    HomeButton,
    ReadMoreButton,
    TagsButton,
  },
})
export default class HomeFooterToolsMobile extends Vue {
  protected isDescriptionAvailable = false;
  protected assetTypes: AssetTypeInterface[] = [...config.assetFilter];

  protected resetAll(): void {
    this.emitter.emit("reset-all-mobile");
    this.emitter.emit("asset-description-toggle", false);
    this.emitter.emit("update-asset-types", []);
    this.emitter.emit("drawer-open", null);
    this.emitter.emit("drawer-tags-open", null);
  }

  protected resetToDefault(): void {
    this.resetAll();
    this.resetFilters();
  }

  protected resetFilters(): void {
    assetsModule.ClearFilters();
    this.emitter.emit("reset-all");
  }

  protected resetForTags(): void {
    this.emitter.emit("reset-all-mobile");
    this.emitter.emit("asset-description-toggle", false);
    this.emitter.emit("update-asset-types", []);
    this.emitter.emit("drawer-open", null);
  }

  protected handleAssetTypeChange(assetTypes: number[]): void {
    this.emitter.emit("asset-description-toggle", false);
    this.emitter.emit("drawer-tags-open", false);
    this.emitter.emit("drawer-open", assetTypes.length);

    if (!assetTypes.length) this.resetFilters();
  }

  mounted(): void {
    this.emitter.on("asset-item-is-selected", () => {
      this.emitter.emit("drawer-open", null);
      this.emitter.emit("drawer-tags-open", null);
      this.resetFilters();
    });
  }
}
