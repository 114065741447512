import {
  FolderDataInterface,
  AssetsInterface,
  FoldersWithAssetsInterface,
  FiltersInterface,
} from "@/types";

export const associateAssetsWithAlbums = (
  albums: FolderDataInterface[],
  assets: AssetsInterface[]
) => {
  return albums.map((album) => {
    const associatedAssets = assets.filter(
      (asset) => asset.albumId === album.id
    );
    return { ...album, assets: associatedAssets };
  });
};

const indexAlbums = (
  albums: FolderDataInterface[]
): { [key: string]: FolderDataInterface } => {
  return albums.reduce(
    (
      acc: { [key: string]: FolderDataInterface },
      album: FolderDataInterface
    ) => {
      acc[album.id] = album;
      return acc;
    },
    {}
  );
};

const collectRelatedAlbums = (
  albums: FolderDataInterface[]
): FolderDataInterface[] => {
  const albumsIndex = indexAlbums(albums);
  const result: FolderDataInterface[] = [];
  const stack: FolderDataInterface[] = albums.filter(
    (album: FolderDataInterface) => album.assets.length > 0
  );

  while (stack.length) {
    const currentAlbum = stack.pop();
    if (!currentAlbum) continue;

    if (!result.some((album) => album.id === currentAlbum.id)) {
      result.unshift(currentAlbum);

      if (currentAlbum.parentId !== null) {
        const parentAlbum = albumsIndex[currentAlbum.parentId as string];

        if (parentAlbum && !stack.includes(parentAlbum)) {
          stack.unshift(parentAlbum);
        }
      }
    }
  }

  return result.sort((a, b) => a.npk - b.npk);
};

export const getAlbumsAssociatedWithAssets = (
  albums: FolderDataInterface[],
  assets: AssetsInterface[]
): FolderDataInterface[] => {
  return collectRelatedAlbums(associateAssetsWithAlbums(albums, assets));
};

const createAlbumTree = (node: any, allFolders: any): any => {
  const subAlbums = allFolders.filter((f: any) => f.parentId === node.id);
  node.folders = subAlbums.map((f: any) => createAlbumTree(f, allFolders));
  return node;
};

export const generateAlbumTree = (
  albums: FolderDataInterface[]
): FoldersWithAssetsInterface[] => {
  const albumsData = [...albums];

  if (!albumsData) {
    return [];
  }

  const rootAlbums = albumsData.filter((album) => album.parentId === null);
  return rootAlbums.map((album) => createAlbumTree(album, albumsData));
};

const tagsMatch = (asset: AssetsInterface, tags: string[]): boolean => {
  return tags.every((tagId: string) => asset.tagIds.includes(tagId));
};

const updateAvailableTags = (
  asset: AssetsInterface,
  tags: string[],
  availableTags: string[]
): void => {
  if (tagsMatch(asset, tags)) {
    const uniqueTags = new Set([...availableTags, ...asset.tagIds]);
    availableTags.splice(0, availableTags.length, ...Array.from(uniqueTags));
  }

  if (!tags.length) {
    availableTags.splice(0, availableTags.length);
  }
};

export const getAvailableTags = ({
  assets,
  tags,
}: {
  assets: AssetsInterface[];
  tags: string[];
}): string[] => {
  const availableTags: string[] = [];
  assets.map((asset: AssetsInterface) =>
    updateAvailableTags(asset, tags, availableTags)
  );
  return availableTags;
};

export const filterAssets = (assets: AssetsInterface[], filter: any) => {
  const isMatchingSearchQuery = (
    assetTitle: string,
    searchQuery: string
  ): boolean => {
    return (
      !searchQuery ||
      assetTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const isAssetTypeMatches = (assetType: number, types: number[]): boolean => {
    return types.length === 0 || types.includes(assetType);
  };

  const isCategoryMatches = (categoryId: string, categories: string[]): boolean => {
    return categories.length === 0 || categoryId === null || categories.includes(categoryId);
  };

  return assets.filter(
    (asset) =>
      isCategoryMatches(asset.categoryId, filter.categories) &&
      isMatchingSearchQuery(asset.title, filter.searchQuery) &&
      isAssetTypeMatches(asset.assetType, filter.assetTypes) &&
      tagsMatch(asset, filter.tags)
  );
};
