import { ApiClientModels } from "@/apiClient/models/api/apiClient.models";
import settingsApiClient from "@/apiClient/settings";
import languagesApiClient from "@/apiClient/languages";
import menuApiClient from "@/apiClient/menu";
import categoriesApiClient from "@/apiClient/categories";
import assetsApiClient from "@/apiClient/assets";
import userApiClient from "@/apiClient/user";

const apiClient: ApiClientModels = {
  settings: settingsApiClient,
  languages: languagesApiClient,
  menu: menuApiClient,
  categories: categoriesApiClient,
  assets: assetsApiClient,
  user: userApiClient,
};

export default apiClient;
