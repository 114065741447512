
import { mixins, Options } from "vue-class-component";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import DefaultButton from "@/components/ui/Buttons/BaseButtonDefault.vue";

const HOME_ROUTE = "3d-viewer/home";

@Options({
  name: "HomeButton",
  components: {
    SvgIcon,
    DefaultButton,
  },
})
export default class HomeButtonMobile extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  protected async resetAndGoToHome(): Promise<void> {
    await this.redirectToRoute("/");
    this.$emit("reset-to-default");
  }

  protected get isHomeRoute(): boolean {
    return this.$route.name === HOME_ROUTE;
  }
}
