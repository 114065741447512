import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7643553f")
const _hoisted_1 = { class: "tools" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_HomeFooterAssetDescription = _resolveComponent("HomeFooterAssetDescription")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumbs),
      (_ctx.isAssetsRoute && _ctx.isDescriptionAvailable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["btn btn--read-more", { 'btn--active': _ctx.isReadMoreExpand }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDescription && _ctx.toggleDescription(...args)))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.getReadMoreKey)), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_HomeFooterAssetDescription, {
      onDescriptionStatus: _cache[1] || (_cache[1] = (status) => (_ctx.isDescriptionAvailable = status))
    })
  ], 64))
}