import {
  assetsUrlsInterface,
  responseInterface,
} from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
  HttpClientPutParameters,
  HttpClientDeleteParameters,
  HttpClientPostParameters,
} from "@/apiClient/models/http/httpClient.models";

export interface AssetsApiClientInterface {
  fetch: (lang?: string) => Promise<responseInterface>;
  fetchAlbums: (lang?: string) => Promise<responseInterface>;
  fetchTags: (lang?: string) => Promise<responseInterface>;
  fetchPinnedAssets: (userId: number) => any;
  pin: (userId: number, assetId: string) => any;
  unpin: (userId: number, assetId: string) => any;
  sortPinnedAssets: (userId: number, ids: { [key: string]: string[] }) => any;
}

export class AssetsApi implements AssetsApiClientInterface {
  private readonly urls!: assetsUrlsInterface;

  constructor(urls: assetsUrlsInterface) {
    this.urls = urls;
  }

  fetch(lang?: string): Promise<responseInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: false,
    };

    return httpClient.get<responseInterface>(getParameters);
  }

  fetchAlbums(lang?: string): Promise<responseInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchAlbums,
      requiresToken: true,
    };

    return httpClient.get<responseInterface>(getParameters);
  }

  fetchTags(lang?: string): Promise<responseInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchTags,
      requiresToken: true,
    };

    return httpClient.get<responseInterface>(getParameters);
  }

  fetchPinnedAssets(userId: any): any {
    const url = this.urls.fetchPinnedAssets.replace(":userId", userId);
    const getParameters: HttpClientGetParameters = {
      url: url,
      requiresToken: false,
    };

    return httpClient.get<responseInterface>(getParameters);
  }

  pin(userId: any, assetId: string): any {
    const url = this.urls.markAssetAsPinned.replace(":userId", userId).replace(":assetId", assetId);

    const PostParameters: HttpClientPutParameters = {
      url: url,
      payload: null,
      requiresToken: false,
    };

    return httpClient.put<responseInterface>(PostParameters);
  }

  unpin(userId: any, assetId: string): any {
    const url = this.urls.unmarkAssetAsPinned.replace(":userId", userId).replace(":assetId", assetId);

    const DeleteParameters: HttpClientDeleteParameters = {
      url: url,
      requiresToken: true,
    };

    return httpClient.delete<responseInterface>(DeleteParameters);
  }

  sortPinnedAssets(userId: any, ids: { [key: string]: string[] }): any {
    const url = this.urls.sortPinnedAssets.replace(":userId", userId);

    const PostParameters: HttpClientPostParameters = {
      url: url,
      payload: ids,
      requiresToken: false,
    };

    return httpClient.post<responseInterface>(PostParameters);
  }
}
