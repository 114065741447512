import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-03d3c0cd")
const _hoisted_1 = { class: "account-wrapper" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_AccountDropdown = _resolveComponent("AccountDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", null, [
      _createVNode(_component_svg_icon, {
        class: "icon icon-account",
        src: `icons/account.svg`
      }, null, 8, ["src"])
    ]),
    _createVNode(_component_AccountDropdown, { "is-auth": _ctx.isUserAuth }, null, 8, ["is-auth"])
  ]))
}