
import { Options, Vue } from "vue-class-component";
import HeaderComponent from "./TheHeader/index.vue";
import AssetsAndAlbumsDrawer from "@/components/layouts/AssetsAndAlbumsDrawer/index.vue";
import TagsAndPinnedAssetsButtonDrawer from "./TagsAndPinnedAssetsButtonDrawer/index.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "pageLayout",
  components: {
    HeaderComponent,
    AssetsAndAlbumsDrawer,
    TagsAndPinnedAssetsButtonDrawer,
  },
})
export default class PageLayout extends Vue {
  $refs!: {
    pageLayout: HTMLFormElement;
  };
  protected mq = useMq();

  private resizeHandler(): void {
    this.setViewPortUnit();
  }

  private setViewPortUnit(): void {
    const vh = window.innerHeight * 0.01;
    this.$refs.pageLayout.style.setProperty("--vh", `${vh}px`);
  }

  beforeMount(): void {
    window.addEventListener("resize", this.resizeHandler);
  }

  mounted(): void {
    this.setViewPortUnit();
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
