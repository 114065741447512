import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "buttons-holder" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isPremiumExpired)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "v1-button-5",
          href: _ctx.getRenewPremiumPath
        }, " Renew Premium ", 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      class: "v1-button-4",
      href: _ctx.getMySubscriptionsPath
    }, "My Subscription", 8, _hoisted_3)
  ]))
}