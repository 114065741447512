
import { Options, Vue } from "vue-class-component";
import { AssetsInterface, FiltersInterface } from "@/types";
import TreeNode from "@/components/ui/BaseAlbumsWithAssets/AlbumList/TreeNode/index.vue";
import { assetsModule } from "@/store";

@Options({
  name: "NewestAlbum",
  components: { TreeNode },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        this.isOpen = false;
      },
    },
  },
})
export default class BaseAlbumsNewest extends Vue {
  protected isOpen = false;

  protected get filteredNewestAssets(): AssetsInterface[] {
    const assets = assetsModule.getNewestAssets;
    if (!assets) return [];

    return assets.filter((asset: AssetsInterface) => {
      const shouldFilterByAssetType =
        this.filters.assetTypes && this.filters.assetTypes.length > 0;

      return (
        !shouldFilterByAssetType ||
        this.filters.assetTypes.includes(asset.assetType)
      );
    });
  }

  private get filters(): FiltersInterface {
    return assetsModule.getFilters;
  }

  protected toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }
}
