import router from "./router";
import { usersModule } from "@/store";

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && to.meta.requiresAuth !== usersModule.IsAuthenticated) {
    return next("/");
  }

  if (to.meta.authentication && to.meta.authentication === usersModule.IsAuthenticated) {
    return next("/");
  }

  next();
});
