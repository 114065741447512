import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-14601fc2")
const _hoisted_1 = { class: "dropdown-wrapper" }
const _hoisted_2 = { class: "menu--sub-menu" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.name
        }, [
          _createElementVNode("a", {
            class: _normalizeClass({ 'has-bullet': item.bullet, 'is-active': item.isActive }),
            href: _ctx.fullPath(item)
          }, _toDisplayString(item.name), 11, _hoisted_3),
          (item.child)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.child, (i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: i.name,
                    class: "has-bullet"
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass({ 'has-bullet': i.bullet }),
                      href: _ctx.fullPath(i)
                    }, _toDisplayString(i.name), 11, _hoisted_5)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}