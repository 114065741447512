
import { mixins, Options } from "vue-class-component";
import ImageWithLoader from "@/components/ui/BaseImage/ImageWithLoader.vue";
import { RoutingUtilityMixin } from "@/mixins";
import { AssetsInterface, AssetsTypesInterface, AssetType } from "@/types";
import { settingsModule } from "@/store";
import { PropType } from "vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "AssetCard",
  components: { ImageWithLoader, SvgIcon },
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
})
export default class index extends mixins(RoutingUtilityMixin) {
  protected asset!: AssetsInterface;

  protected get isModels(): AssetType {
    return AssetType.Models;
  }

  protected get isVideo(): AssetType {
    return AssetType.Video;
  }

  protected get assetMediaUrl(): string {
    if (this.asset?.assetType == 1) {
      return this.asset?.thumbs?.["140x140"]?.url || "";
    }

    return this.asset?.src;
  }

  protected getAssetsTypesInString(types: Array<number>): string {
    return settingsModule.getSettings
      .map((s: AssetsTypesInterface) => types.includes(s.id) && s.title)
      .filter(Boolean)
      .join(" / ");
  }
}
