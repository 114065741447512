import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6e379b5d")
const _hoisted_1 = { class: "tag-filter" }
const _hoisted_2 = { class: "dropdown-holder" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_TagsDropdown = _resolveComponent("TagsDropdown")!
  const _component_SelectedTags = _resolveComponent("SelectedTags")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "inner",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["tag-dropdown-button", [_ctx.isOpen && 'is-active']])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("tags")), 1),
          _createVNode(_component_SvgIcon, {
            class: "icon icon--small",
            local: "",
            src: require(`./images/arrow-down.svg`)
          }, null, 8, ["src"])
        ], 2),
        _createVNode(_component_TagsDropdown, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          categories: _ctx.getCategories,
          "tags-data": _ctx.getTags,
          "is-open": _ctx.isOpen,
          onUpdateTags: _ctx.updateTags,
          onUpdateCategories: _ctx.updateCategories
        }, null, 8, ["categories", "tags-data", "is-open", "onUpdateTags", "onUpdateCategories"])
      ]),
      _createVNode(_component_SelectedTags, {
        "selected-tags": [..._ctx.selectedCategories, ..._ctx.selectedTags],
        onUpdateTags: _ctx.updateTags
      }, null, 8, ["selected-tags", "onUpdateTags"])
    ])
  ], 512)), [
    [_directive_click_outside, _ctx.closeTags]
  ])
}