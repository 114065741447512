
import { Vue, Options } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

const ALBUMS_ROUTE = "3d-viewer/albums/root";

@Options({
  name: "AlbumsButton",
  components: {
    SvgIcon,
  },
})
export default class AlbumsButton extends Vue {
  protected get isAlbumsRoute(): boolean {
    return this.$route.name === ALBUMS_ROUTE;
  }
}
