
import { mixins, Options } from "vue-class-component";
import { HasAccessToAssetMixin } from "@/mixins";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

const PINNED_ASSETS_ROUTE = "3d-viewer/pinnedAssets";

@Options({
  name: "AlbumsButton",
  components: {
    SvgIcon,
  },
})
export default class AlbumsButton extends mixins(HasAccessToAssetMixin) {
  protected get isMyAssetsRoute(): boolean {
    return this.$route.name == PINNED_ASSETS_ROUTE;
  }
}
