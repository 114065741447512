
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin } from "@/mixins";
import { FolderDataInterface } from "@/types";
import { assetsModule } from "@/store";
import { PropType } from "vue";
import ImageWithLoader from "@/components/ui/BaseImage/ImageWithLoader.vue";

@Options({
  name: "AlbumComponent",
  components: { ImageWithLoader },
  props: {
    album: {
      type: Object as PropType<FolderDataInterface>,
      default: () => [],
    },
    newest: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AlbumComponent extends mixins(RoutingUtilityMixin) {
  protected hasChildren(id: string): boolean {
    return assetsModule.getAlbums.some((album) => album.parentId === id);
  }
}
