import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-42cde01c")
const _hoisted_1 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn btn--read-more", {
      'is-active': _ctx.isReadMoreExpand,
      'is-disabled': !_ctx.isAssetsRoute || !_ctx.isDescriptionAvailable,
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDescription && _ctx.toggleDescription(...args)))
  }, [
    (['xs'].includes(_ctx.mq.current))
      ? (_openBlock(), _createBlock(_component_SvgIcon, {
          key: 0,
          class: "icon icon--medium",
          local: "",
          src: require('./images/read-more-icon.svg')
        }, null, 8, ["src"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.getReadMoreKey)), 1))
  ], 2))
}