import { AccessTypes } from "@/types";

const accessRange = Object.keys(AccessTypes)
  .filter((a: any) => !isNaN(a))
  .map((a: any) => parseInt(a));

const isValidAccessType = (access: number) => accessRange.includes(access);

export const checkAccess = (userAccess: number, contentAccess: number) => {
  if (!isValidAccessType(userAccess)) {
    userAccess = AccessTypes.Guest;
  }

  if (!isValidAccessType(contentAccess)) {
    contentAccess = AccessTypes.Guest;
  }

  return userAccess >= contentAccess;
};
