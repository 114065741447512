import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5e13417e")
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["value", "id"]
const _hoisted_3 = ["for"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({ ref: "input" }, _ctx.$attrs, {
      value: _ctx.value,
      id: _ctx.makeId,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value)))
    }), null, 16, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.makeId,
      class: "label--error"
    }, [
      _renderSlot(_ctx.$slots, "error", {}, undefined, true)
    ], 8, _hoisted_3)
  ]))
}