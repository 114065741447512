
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Page404",
  props: {
    keyWord: {
      type: String,
    },
  },
})
export default class Page404 extends Vue {
  protected keyWord!: string;
  protected get subtitleText(): string {
    return this.keyWord
      ? `The ${this.keyWord} you’re looking for doesn’t exist`
      : "404. The page you’re looking for doesn’t exist.";
  }
}
