import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!

  return (_openBlock(), _createBlock(_component_DefaultButton, {
    onClick: _ctx.toggleTags,
    class: _normalizeClass(["btn btn--extra-large", { 'is-active': _ctx.isOpen }]),
    local: ""
  }, {
    svgIcon: _withCtx(() => [
      _createVNode(_component_SvgIcon, {
        class: "icon icon--medium",
        local: "",
        src: require(`./images/tags.svg`)
      }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["onClick", "class"]))
}