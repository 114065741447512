
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin } from "@/mixins";
import { PropType } from "vue";
import { AssetsInterface, AssetType } from "@/types";

@Options({
  name: "ImageComponent",
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
})
export default class ImageComponent extends mixins(RoutingUtilityMixin) {
  private asset!: AssetsInterface;
  private basePath = process.env.VUE_APP_RESOURCE_URL;

  protected get getPath(): string {
    try {
      if (!this.asset) {
        throw new Error("Asset is not available.");
      }

      if (this.asset.src) {
        return `${this.basePath}/${this.asset.src}`;
      }

      return "";
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }
}
