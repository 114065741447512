
import { Options, mixins } from "vue-class-component";
import { AssetsInterface, FoldersWithAssetsInterface } from "@/types";
import { HasAccessToAssetMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import { assetsModule } from "@/store";
import { useMq } from "vue3-mq";

@Options({
  name: "HomeFooterAssetDescription",
  watch: {
    hasDescription(val) {
      this.$emit("description-status", val);
    },
  },
})
export default class HomeFooterAssetDescription extends mixins(
  HasAccessToAssetMixin,
  AlbumAndAssetManagementMixin
) {
  private mq = useMq();
  protected isReadMoreExpand = false;

  private get getFilteredAlbums(): FoldersWithAssetsInterface[] {
    const isDesktop = ["lg", "xl", "xxl"].includes(this.mq.current);

    return isDesktop
      ? assetsModule.getFilteredAlbums
      : assetsModule.getFoldersWithAssets;
  }

  private get getAssetsData(): AssetsInterface[] {
    return this.extractNestedAssets(this.getFilteredAlbums);
  }

  private get getAsset(): AssetsInterface | undefined {
    return this.getAssetsData.find(
      (a) => a.slug === this.$route.params.assetSlug
    );
  }

  private get hasDescription(): boolean {
    return !!this.getAsset?.description;
  }

  protected get isAssetsRoute(): boolean {
    return !!this.$route.params.assetSlug;
  }

  mounted(): void {
    this.$emit("description-status", this.hasDescription);

    this.emitter.on(
      "asset-description-toggle",
      (state: boolean) => (this.isReadMoreExpand = state)
    );
  }
}
