
import { mixins, Options } from "vue-class-component";
import { RoutingUtilityMixin, AlbumAndAssetManagementMixin } from "@/mixins";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "AlbumButton",
  components: {
    SvgIcon,
  },
  props: {
    isDescriptionAvailable: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AlbumButtonMobile extends mixins(
  RoutingUtilityMixin,
  AlbumAndAssetManagementMixin
) {
  protected mq = useMq();
  private isReadMoreExpand = false;

  protected toggleDescription(): void {
    this.emitter.emit(
      "asset-description-toggle",
      (this.isReadMoreExpand = !this.isReadMoreExpand)
    );
  }

  protected get getReadMoreKey(): string {
    return this.isReadMoreExpand ? "read_less" : "read_more";
  }

  protected get isAssetsRoute(): boolean {
    return !!this.$route.params.assetSlug;
  }

  mounted(): void {
    this.emitter.on("asset-description-toggle", (state: boolean): void => {
      this.isReadMoreExpand = state;
    });
  }
}
