import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(!!_ctx.item.child ? 'MenuItemButton' : 'MenuItemLink'), { item: _ctx.item }, {
    item: _withCtx(() => [
      _renderSlot(_ctx.$slots, "item", {
        link: _ctx.item.link,
        title: _ctx.item.title,
        itemName: _ctx.item.name
      })
    ]),
    _: 3
  }, 8, ["item"]))
}