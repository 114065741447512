import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5bd1f99e")
const _hoisted_1 = ["data-folder-key"]
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "albums"
}
const _hoisted_5 = {
  key: 1,
  class: "assets"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree")!
  const _component_TreeNode = _resolveComponent("TreeNode")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.treeData, (node) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass([
      'album',
      _ctx.isOpen(node.slug) && 'is-open',
      _ctx.isActive(node.slug) && 'is-active',
    ]),
      key: node.id,
      "data-folder-key": node.slug
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: "title",
          onClick: ($event: any) => (_ctx.$emit('album-click', node))
        }, _toDisplayString(node.name), 9, _hoisted_3),
        (_ctx.hasSubAlbum(node))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Tree, {
                "tree-data": node.folders,
                "active-album": _ctx.activeAlbum,
                depth: _ctx.depth + 1,
                "path-ids": _ctx.pathIds,
                onAlbumClick: _cache[0] || (_cache[0] = (n) => _ctx.$emit('album-click', n))
              }, null, 8, ["tree-data", "active-album", "depth", "path-ids"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasAssets(node))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(node.assets, (asset) => {
                return (_openBlock(), _createBlock(_component_TreeNode, {
                  key: asset.id,
                  asset: asset
                }, null, 8, ["asset"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ], 10, _hoisted_1))
  }), 128))
}