
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "MenuBtn",
  components: {
    SvgIcon
  },
  props: {
    isActive: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    iconName() {
      return this.isActive ? "close-icon" : "hamburger-menu";
    }
  }
})
export default class MenuBtn extends Vue {}
