import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f1554f72")
const _hoisted_1 = { class: "selected-tags" }
const _hoisted_2 = { class: "tag-name" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnselectTagButton = _resolveComponent("UnselectTagButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTags, (tag) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tag.id,
        class: "tag"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getTagName(tag)), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["button-holder", [tag.id === 'default' && 'is-disabled']])
        }, [
          (!_ctx.isDefaultTag(tag))
            ? (_openBlock(), _createBlock(_component_UnselectTagButton, {
                key: 0,
                onClick: ($event: any) => (_ctx.unselectTag(tag))
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128)),
    (_ctx.showClearAllButton)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "clear-all",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitter.emit('reset-tags-and-categories')))
        }, _toDisplayString(_ctx.$t("clear_all")), 1))
      : _createCommentVNode("", true)
  ]))
}